// buymodules/AccountPurchaseOverview.js
import React from 'react';
import '../Buy.css'; // Ensure the path is correct

function AccountPurchaseOverview({ purchaseData, accountNickname }) {
    // Destructure the purchase data for easier access
    const { Today, "Last 5 Days": Last5Days, "Last 10 Days": Last10Days, "Last 30 Days": Last30Days } = purchaseData;

    // Helper function to calculate total BuyPrice
    const calculateTotal = (items) => {
        return items.reduce((acc, item) => acc + (item.BuyPrice || 0), 0).toFixed(2);
    };

    return (
        <div className="account-purchase-overview">
            <h2 className="small-heading">
                {accountNickname !== 'No Account Selected'
                    ? `${accountNickname} Purchase Overview`
                    : 'Account Purchase Overview'}
            </h2>
            <div className="purchase-options">
                <div className="purchase-box">
                    <h3>Today's Purchases</h3>
                    <p className="box-value">
                        ${Today.length > 0 ? calculateTotal(Today) : "0.00"}
                    </p>
                </div>
                <div className="purchase-box">
                    <h3>Last 5 Days</h3>
                    <p className="box-value">
                        ${Last5Days.length > 0 ? calculateTotal(Last5Days) : "0.00"}
                    </p>
                </div>
                <div className="purchase-box">
                    <h3>Last 10 Days</h3>
                    <p className="box-value">
                        ${Last10Days.length > 0 ? calculateTotal(Last10Days) : "0.00"}
                    </p>
                </div>
                <div className="purchase-box">
                    <h3>Last 30 Days</h3>
                    <p className="box-value">
                        ${Last30Days.length > 0 ? calculateTotal(Last30Days) : "0.00"}
                    </p>
                </div>
            </div>
        </div>
    );
}

export default AccountPurchaseOverview;
