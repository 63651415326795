// Buy.js
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from './Homepagemodules/Navbar';
import Footer from './Homepagemodules/homepagefooter';
import './Buy.css';
import PurchaseOverview from './buymodules/PurchaseOverview';
import AccountPurchaseOverview from './buymodules/AccountPurchaseOverview'; // Import the new component
import ToggleSection from './buymodules/ToggleSection';
import BuySettingsMenu from './buymodules/BuySettingsMenu';
import './components/loadingscreen.css';
function Buy() {
    // State Variables
    const [loading, setLoading] = useState(true);
    const [authenticated, setAuthenticated] = useState(false);
    const [globalToggles, setGlobalToggles] = useState({});
    const [accountToggles, setAccountToggles] = useState({});
    const [accountNicknames, setAccountNicknames] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);
    const [accountFunctionalities, setAccountFunctionalities] = useState([]);
    const [filteredAccounts, setFilteredAccounts] = useState([]);
    const [priceRanges, setPriceRanges] = useState({});
    const [liquidityValues, setLiquidityValues] = useState({});
    const [buyThresholds, setBuyThresholds] = useState({});
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [settingsDropdownVisible, setSettingsDropdownVisible] = useState(false);
    
    // State Variable for Account-Specific Purchase Data
    const [accountPurchaseData, setAccountPurchaseData] = useState({
        Today: [],
        "Last 5 Days": [],
        "Last 10 Days": [],
        "Last 30 Days": [],
    });

    const [purchaseData, setPurchaseData] = useState({
        Today: [],
        "Last 5 Days": [],
        "Last 10 Days": [],
        "Last 30 Days": [],
    });

    const navigate = useNavigate();
    const hasFetched = useRef(false);
    const searchRef = useRef(null);

    // Mapping between toggle names and functionalities
    const toggleFunctionalityMap = {
        marketbuying_functionality: 'marketbuying_functionality',
        marketselling_functionality: 'marketselling_functionality',
        csgoempirebuying_functionality: 'csgoempirebuying_functionality',
        csgoempireselling_functionality: 'csgoempireselling_functionality',
        waxpeerbuying_functionality: 'waxpeerbuying_functionality',
        waxpeerselling_functionality: 'waxpeerselling_functionality',
        buying500_functionality: 'buying500_functionality',
        selling500_functionality: 'selling500_functionality',
        shadowpaybuying_functionality: 'shadowpaybuying_functionality',
        shadowpayselling_functionality: 'shadowpayselling_functionality',
    };

    // Reverse mapping for functionalities to toggle names
    const functionalityToggleReverseMap = Object.fromEntries(
        Object.entries(toggleFunctionalityMap).map(([k, v]) => [v, k])
    );

    // Fetch Authentication and Initial Data
    useEffect(() => {
        const checkAuthAndFetchData = async () => {
            try {
                if (hasFetched.current) return;
                hasFetched.current = true;

                // Retrieve the authentication token
                const token = localStorage.getItem('authToken');

                // Check if the user is authenticated
                const authResponse = await fetch('/api/auth/isAuthenticated', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    credentials: 'include',
                });

                if (authResponse.ok) {
                    setAuthenticated(true);

                    // Fetch global toggles
                    const togglesResponse = await fetch('/api/toggles', {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        },
                        credentials: 'include',
                    });

                    if (togglesResponse.ok) {
                        const togglesData = await togglesResponse.json();
                        setGlobalToggles(togglesData.globaltoggles);
                    } else {
                        const errorData = await togglesResponse.json();
                        console.error(errorData.message || 'Failed to fetch toggles');
                    }

                    // Fetch account nicknames
                    const accountsResponse = await fetch('/api/users/account-nicknames', {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        },
                        credentials: 'include',
                    });

                    if (accountsResponse.ok) {
                        const accountsData = await accountsResponse.json();
                        setAccountNicknames(accountsData.accountNicknames);
                        setFilteredAccounts(accountsData.accountNicknames);
                    } else {
                        const errorData = await accountsResponse.json();
                        console.error(errorData.message || 'Failed to fetch account nicknames');
                    }

                    // Fetch global purchase data
                    const purchasesResponse = await fetch('/api/purchases/global', {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        },
                        credentials: 'include',
                    });

                    if (purchasesResponse.ok) {
                        const purchasesData = await purchasesResponse.json();
                        setPurchaseData(purchasesData);
                    } else {
                        const errorData = await purchasesResponse.json();
                        console.error(errorData.message || 'Failed to fetch global purchases');
                    }

                } else {
                    // If not authenticated, redirect to home page
                    navigate('/');
                }
            } catch (error) {
                console.error('Error:', error);
                navigate('/');
            } finally {
                setLoading(false);
            }
        };

        checkAuthAndFetchData();
    }, [navigate]);

    // Handle Clicks Outside the Search Dropdown
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchRef.current && !searchRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Handler: Toggle Global Toggles
    const handleGlobalToggleChange = async (toggleName) => {
        try {
            const updatedToggles = {
                ...globalToggles,
                [toggleName]: !globalToggles[toggleName],
            };

            setGlobalToggles(updatedToggles);

            const token = localStorage.getItem('authToken');

            const response = await fetch('/api/toggles', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                credentials: 'include',
                body: JSON.stringify({
                    globaltoggles: {
                        [toggleName]: updatedToggles[toggleName],
                    },
                }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to update toggle');
            }
        } catch (error) {
            console.error('Error updating toggle:', error);
            setGlobalToggles((prevToggles) => ({
                ...prevToggles,
                [toggleName]: !prevToggles[toggleName],
            }));
        }
    };

    // Handler: Toggle Account-Specific Functionalities
    const handleAccountToggleChange = async (toggleName) => {
        try {
            const functionality = toggleFunctionalityMap[toggleName];
            if (!functionality) {
                console.warn(`No functionality mapped for toggle: ${toggleName}`);
                return;
            }

            const newState = !accountToggles[toggleName];

            const currentFunctionalities = Array.isArray(accountFunctionalities) ? accountFunctionalities : [];

            setAccountToggles((prevToggles) => ({
                ...prevToggles,
                [toggleName]: newState,
            }));

            let updatedFunctionalities;
            if (newState) {
                updatedFunctionalities = [...currentFunctionalities, functionality];
            } else {
                updatedFunctionalities = currentFunctionalities.filter((func) => func !== functionality);
            }
            setAccountFunctionalities(updatedFunctionalities);

            const token = localStorage.getItem('authToken');

            const response = await fetch(`/api/accounts/${encodeURIComponent(searchInput)}/functionality`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                credentials: 'include',
                body: JSON.stringify({ functionality, state: newState }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to update functionality.');
            }
        } catch (error) {
            console.error('Error updating functionality:', error);
            fetchAccountFunctionalities();
        }
    };

    // Handler: Search Input Change
    const handleSearchChange = (e) => {
        const query = e.target.value;
        setSearchInput(query);
        if (!showDropdown) setShowDropdown(true);

        if (query.trim() === '') {
            setFilteredAccounts(accountNicknames);
        } else {
            const filtered = accountNicknames.filter((nickname) =>
                nickname.toLowerCase().includes(query.toLowerCase())
            );
            setFilteredAccounts(filtered);
        }
    };

    // Handler: Select an Account from Dropdown
    const handleAccountSelect = async (selectedNickname) => {
        try {
            setSearchInput(selectedNickname);
            setShowDropdown(false);
            setSelectedAccount(selectedNickname);

            // Fetch account-specific purchase data
            await fetchPurchaseData(selectedNickname);

            // Fetch account functionalities
            await fetchAccountFunctionalities(selectedNickname);

            // Fetch account-specific modifiers
            await fetchModifiers(selectedNickname);
        } catch (error) {
            console.error('Error selecting account:', error);
        }
    };

    // Helper Function: Fetch Account Functionalities
    const fetchAccountFunctionalities = async (nickname) => {
        try {
            const token = localStorage.getItem('authToken');

            const response = await fetch(`/api/accounts/${encodeURIComponent(nickname)}/functionality`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                credentials: 'include',
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to fetch account functionalities.');
            }

            const data = await response.json();

            // Initialize accountToggles based on fetched functionalities
            let updatedAccountToggles = {};

            // Set all toggles to false initially
            Object.keys(toggleFunctionalityMap).forEach((toggle) => {
                updatedAccountToggles[toggle] = false;
            });

            // Update toggles based on fetched functionalities
            Object.keys(data.functionalities).forEach((funcKey) => {
                const toggleName = functionalityToggleReverseMap[funcKey];
                if (toggleName) {
                    updatedAccountToggles[toggleName] = data.functionalities[funcKey];
                }
            });

            setAccountToggles(updatedAccountToggles);
        } catch (error) {
            console.error('Error fetching account functionalities:', error);
        }
    };

    // Helper Function: Fetch Purchase Data (Global or Specific)
    const fetchPurchaseData = async (nickname = 'global') => {
        try {
            const token = localStorage.getItem('authToken');
            const endpoint = nickname === 'global' ? '/api/purchases/global' : `/api/purchases/${encodeURIComponent(nickname)}`;

            const response = await fetch(endpoint, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                credentials: 'include',
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to fetch purchases');
            }

            const data = await response.json();

            if (nickname === 'global') {
                setPurchaseData(data);
            } else {
                setAccountPurchaseData(data);
            }
        } catch (error) {
            console.error('Error fetching purchases:', error);
        }
    };

    // Function to fetch modifiers (global or account-specific)
    const fetchModifiers = async (nickname = null) => {
        try {
            const target = nickname ? nickname : 'global';

            const token = localStorage.getItem('authToken');

            const response = await fetch(`/api/modifiers/${encodeURIComponent(target)}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                credentials: 'include',
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to fetch modifiers.');
            }

            const data = await response.json();

            // Since the modifiers are under data.modifiers, we need to access data.modifiers
            const modifiersData = data.modifiers || {};

            console.log('Fetched Modifiers Data:', modifiersData);

            // Map the fetched data to state variables
            const fetchedPriceRanges = {};
            const fetchedLiquidityValues = {};
            const fetchedBuyThresholds = {};

            const marketKeyMap = {
                'market': 'market',
                'csgoempire': 'csgoempire',
                'waxpeer': 'waxpeer',
                'csgo500': 'csgo500',
                'shadowpay': 'shadowpay',
            };

            Object.keys(marketKeyMap).forEach((marketKey) => {
                const dbKey = marketKeyMap[marketKey];

                // Price Ranges
                fetchedPriceRanges[marketKey] = {
                    minPrice:
                        modifiersData[`${dbKey}minrange`] !== undefined
                            ? String(modifiersData[`${dbKey}minrange`])
                            : '',
                    maxPrice:
                        modifiersData[`${dbKey}maxrange`] !== undefined
                            ? String(modifiersData[`${dbKey}maxrange`])
                            : '',
                };

                // Liquidity
                fetchedLiquidityValues[marketKey] = {
                    liquidity:
                        modifiersData[`${dbKey}liquidity`] !== undefined
                            ? String(modifiersData[`${dbKey}liquidity`])
                            : '',
                };

                // Buy Thresholds
                fetchedBuyThresholds[marketKey] = {
                    threshold:
                        modifiersData[`${dbKey}purchase`] !== undefined
                            ? String(modifiersData[`${dbKey}purchase`])
                            : '',
                };
            });

            // Update state variables
            if (nickname === 'global' || nickname === null) {
                setPriceRanges(fetchedPriceRanges);
                setLiquidityValues(fetchedLiquidityValues);
                setBuyThresholds(fetchedBuyThresholds);
            } else {
                // For account-specific modifiers, you might want separate state variables
                // Here, for simplicity, we're using the same state variables
                setPriceRanges(fetchedPriceRanges);
                setLiquidityValues(fetchedLiquidityValues);
                setBuyThresholds(fetchedBuyThresholds);
            }
        } catch (error) {
            console.error('Error fetching modifiers:', error);
        }
    };

    // Handler: Open Settings Menu
    const openSettingsMenu = async () => {
        await fetchModifiers(selectedAccount);
        setSettingsDropdownVisible(true);
    };

    // Handler: Clear Account Selection
    const clearAccountSelection = () => {
        setSearchInput('');
        setSelectedAccount(null);
        setAccountFunctionalities([]);
        setAccountToggles({});
        // Reset modifiers to global when account is cleared
        fetchModifiers(); // Fetch global modifiers

        // Fetch global purchase data again to reset PurchaseOverview
        fetchPurchaseData('global');
    };

    // Handlers for BuySettingsMenu (Price Range, Liquidity, Buy Threshold)
    const handlePriceRangeChange = (marketKey, key, value) => {
        setPriceRanges((prev) => ({
            ...prev,
            [marketKey]: { ...prev[marketKey], [key]: value },
        }));
    };

    const handleLiquidityChange = (marketKey, value) => {
        setLiquidityValues((prev) => ({
            ...prev,
            [marketKey]: { ...prev[marketKey], liquidity: value },
        }));
    };

    const handleBuyThresholdChange = (marketKey, value) => {
        setBuyThresholds((prev) => ({
            ...prev,
            [marketKey]: { ...prev[marketKey], threshold: value },
        }));
    };

    // Function to close the settings menu
    const closeMenu = () => {
        setSettingsDropdownVisible(false);
    };

    // Handler for Save button click
    const handleSaveChanges = async () => {
        try {
            const modifiers = {};

            const marketKeyMap = {
                'market': 'market',
                'csgoempire': 'csgoempire',
                'waxpeer': 'waxpeer',
                'csgo500': 'csgo500',
                'shadowpay': 'shadowpay',
            };

            Object.keys(marketKeyMap).forEach((marketKey) => {
                const dbKey = marketKeyMap[marketKey];

                // Price Ranges
                if (priceRanges[marketKey]) {
                    modifiers[`${dbKey}minrange`] = parseFloat(priceRanges[marketKey].minPrice) || 0;
                    modifiers[`${dbKey}maxrange`] = parseFloat(priceRanges[marketKey].maxPrice) || 0;
                }

                // Liquidity
                if (liquidityValues[marketKey]) {
                    modifiers[`${dbKey}liquidity`] = parseFloat(liquidityValues[marketKey].liquidity) || 0;
                }

                // Buy Thresholds
                if (buyThresholds[marketKey]) {
                    modifiers[`${dbKey}purchase`] = parseFloat(buyThresholds[marketKey].threshold) || 0;
                }
            });

            const target = selectedAccount ? selectedAccount : 'global';

            const token = localStorage.getItem('authToken');

            const response = await fetch(`/api/modifiers/${encodeURIComponent(target)}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                credentials: 'include',
                body: JSON.stringify(modifiers),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to update modifiers.');
            }

            console.log('Modifiers updated successfully.');

            closeMenu();
        } catch (error) {
            console.error('Error updating modifiers:', error);
        }
    };

    // Handler for Cancel button click
    const handleCancelChanges = () => {
        closeMenu();
    };

    // Conditional Rendering: Loading and Authentication
    if (loading) {
        return (
          <div className="loading-screen">
            <div className="loading-spinner"></div>
          </div>
        );
      }

    if (!authenticated) {
        return null;
    }

    return (
        <div className="buy-page">
            <Navbar authenticated={authenticated} setAuthenticated={setAuthenticated} />
            <div className="buy-content">
                {/* Purchase Overview Section */}
                <PurchaseOverview purchaseData={purchaseData} />

                {/* Toggle Section */}
                <ToggleSection
                    searchInput={searchInput}
                    accountToggles={accountToggles}
                    globalToggles={globalToggles}
                    handleGlobalToggleChange={handleGlobalToggleChange}
                    handleAccountToggleChange={handleAccountToggleChange}
                    clearAccountSelection={clearAccountSelection}
                    searchRef={searchRef}
                    handleSearchChange={handleSearchChange}
                    showDropdown={showDropdown}
                    filteredAccounts={filteredAccounts}
                    handleAccountSelect={handleAccountSelect}
                    setShowDropdown={setShowDropdown}
                    context={selectedAccount ? 'specific' : 'global'}
                    selectedAccount={selectedAccount}
                    priceRanges={priceRanges}
                    liquidityValues={liquidityValues}
                    onPriceRangeChange={handlePriceRangeChange}
                    onLiquidityChange={handleLiquidityChange}
                    setSettingsDropdownVisible={openSettingsMenu}
                    settingsDropdownVisible={settingsDropdownVisible}
                />

                {/* Account-Specific Purchase Overview Section */}
                <AccountPurchaseOverview
                    purchaseData={
                        selectedAccount
                            ? accountPurchaseData
                            : { Today: [], "Last 5 Days": [], "Last 10 Days": [], "Last 30 Days": [] }
                    }
                    accountNickname={selectedAccount ? selectedAccount : 'No Account Selected'}
                />
            </div>

            {/* Buy Settings Menu: Conditionally rendered */}
            {settingsDropdownVisible && (
                <div className="buy-settings-menu-container">
                    <BuySettingsMenu
                        context={selectedAccount ? 'specific' : 'global'}
                        selectedAccount={selectedAccount}
                        priceRanges={priceRanges}
                        liquidityValues={liquidityValues}
                        buyThresholds={buyThresholds}
                        onPriceRangeChange={handlePriceRangeChange}
                        onLiquidityChange={handleLiquidityChange}
                        onBuyThresholdChange={handleBuyThresholdChange}
                        onSaveChanges={handleSaveChanges}
                        onCancelChanges={handleCancelChanges}
                        closeMenu={closeMenu}
                    />
                </div>
            )}

            <Footer />
        </div>
    );
}

export default Buy;
