// src/components/SaleOverview/SaleOverview.js
import React from 'react';
import './SaleOverview.css';

function SaleOverview({ salesData, selectedNickname }) {
    // Destructure the sales data for easier access
    const { Today, "Last 5 Days": Last5Days, "Last 10 Days": Last10Days, "Last 30 Days": Last30Days } = salesData;
   
    // Helper function to calculate total sales amount using SoldPrice
    const calculateTotal = (items) => {
        return items.reduce((acc, item) => acc + (parseFloat(item.SoldPrice) || 0), 0);
    };

    // Calculate totals
    const todayTotal = calculateTotal(Today);
    const last5DaysTotal = calculateTotal(Last5Days);
    const last10DaysTotal = calculateTotal(Last10Days);
    const last30DaysTotal = calculateTotal(Last30Days);

    // If you want cumulative totals (e.g., Last 5 Days includes Today)
    // This is already handled by overlapping categories
    // So "Last 5 Days" already includes "Today"

    console.log("Today's Array:", Today); // For debugging purposes
    console.log("Today Total:", todayTotal);
    console.log("Last 5 Days Total:", last5DaysTotal);
    console.log("Last 10 Days Total:", last10DaysTotal);
    console.log("Last 30 Days Total:", last30DaysTotal);
    console.log("Last 5 Days Array:", Last5Days);

    return (
        <div className="sale-overview">
            <h2 className="small-heading">
                {selectedNickname !== 'No Account Selected'
                    ? `${selectedNickname} Sales Overview`
                    : 'Account Sales Overview'}
            </h2>
            <div className="sale-options">
                <div className="sale-box">
                    <h3>Today's Sales</h3>
                    <p className="box-value">${todayTotal.toFixed(2)}</p>
                </div>
                <div className="sale-box">
                    <h3>Last 5 Days</h3>
                    <p className="box-value">${last5DaysTotal.toFixed(2)}</p>
                </div>
                <div className="sale-box">
                    <h3>Last 10 Days</h3>
                    <p className="box-value">${last10DaysTotal.toFixed(2)}</p>
                </div>
                <div className="sale-box">
                    <h3>Last 30 Days</h3>
                    <p className="box-value">${last30DaysTotal.toFixed(2)}</p>
                </div>
            </div>
        </div>
    );
}

export default SaleOverview;
