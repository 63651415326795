// buymodules/ToggleSection.js
import React from 'react';
import market from '../pictures/market.png';
import csgoempire from '../pictures/csgoempire.png';
import waxpeer from '../pictures/waxpeer.svg';
import csgo500 from '../pictures/csgo500.gif';
import shadowpay from '../pictures/shadowpay.svg';
import AccountSearch from './AccountSearch';
import AdditionalPurchaseOverview from './AdditionalPurchaseOverview';

function ToggleSection({
    searchInput,
    accountToggles,
    globalToggles,
    handleGlobalToggleChange,
    handleAccountToggleChange,
    clearAccountSelection,
    searchRef,
    handleSearchChange,
    showDropdown,
    filteredAccounts,
    handleAccountSelect,
    setShowDropdown,
    // Settings props
    context,
    selectedAccount,
    priceRanges,
    liquidityValues,
    onPriceRangeChange,
    onLiquidityChange,
    // New props for settings menu visibility
    setSettingsDropdownVisible,
    settingsDropdownVisible,
}) {
    const toggles = [
        { name: 'marketbuying_functionality', label: 'Market.CSGO', icon: market },
        { name: 'csgoempirebuying_functionality', label: 'CSGOEmpire', icon: csgoempire },
        { name: 'waxpeerbuying_functionality', label: 'Waxpeer', icon: waxpeer },
        { name: 'buying500_functionality', label: 'CSGO500', icon: csgo500 },
        { name: 'shadowpaybuying_functionality', label: 'ShadowPay', icon: shadowpay },
    ];

    // Determine the heading based on the context and selectedAccount
    const getHeading = () => {
        if (context === 'specific' && selectedAccount) {
            return `${selectedAccount} Toggles`;
        }
        return 'Global Toggles';
    };

    return (
        <div className="toggle-section">
            <div className="toggle-header">
                <h2 className="small-heading">{getHeading()}</h2>
                {/* Optional: Button to clear account selection */}
                {searchInput && (
                    <button className="clear-selection-button" onClick={clearAccountSelection}>
                        Clear Account Selection
                    </button>
                )}
                {/* Integrate the AccountSearch component */}
                <AccountSearch
                    searchInput={searchInput}
                    handleSearchChange={handleSearchChange}
                    showDropdown={showDropdown}
                    filteredAccounts={filteredAccounts}
                    handleAccountSelect={handleAccountSelect}
                    searchRef={searchRef}
                    setShowDropdown={setShowDropdown}
                    // Settings props
                    context={context}
                    selectedAccount={selectedAccount}
                    priceRanges={priceRanges}
                    liquidityValues={liquidityValues}
                    onPriceRangeChange={onPriceRangeChange}
                    onLiquidityChange={onLiquidityChange}
                    setSettingsDropdownVisible={setSettingsDropdownVisible} // Pass the setter
                    settingsDropdownVisible={settingsDropdownVisible} // Pass the current visibility state
                />
            </div>

            <div className="toggle-options">
                {/* Define a list of toggles with their corresponding names and icons */}
                {toggles.map((toggle) => (
                    <div className="toggle-box" key={toggle.name}>
                        <img src={toggle.icon} alt={`${toggle.label} Buying`} className="icon" />
                        {toggle.label}
                        <label className="switch">
                            <input
                                type="checkbox"
                                checked={
                                    searchInput
                                        ? accountToggles[toggle.name] || false // Use account toggles when an account is selected
                                        : globalToggles[toggle.name] || false // Use global toggles when no account is selected
                                }
                                onChange={() => {
                                    if (searchInput) {
                                        // If an account is selected, handle account-specific toggle changes
                                        handleAccountToggleChange(toggle.name);
                                    } else {
                                        // Otherwise, handle global toggle changes
                                        handleGlobalToggleChange(toggle.name);
                                    }
                                }}
                            />
                            <span className="slider"></span>
                        </label>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default ToggleSection;
