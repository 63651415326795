import React from 'react';
import './Marketplaces.css';
import market from '../pictures/market.png';
import csgoempire from '../pictures/csgoempire.png';
import waxpeer from '../pictures/waxpeer.svg';
import csgo500 from '../pictures/csgo500.jpg';
import shadowpay from '../pictures/shadowpay.svg';
import csgoorollico from '../pictures/roll2.png';

const Marketplaces = () => {
  const marketplaces = [
    {
      name: 'Market.CSGO',
      logo: market,
      className: 'market-csgo',
      logoWidth: '80px', // Set individual sizes here
    },
    {
      name: 'WaxPeer',
      logo: waxpeer,
      className: 'waxpeer',
      logoWidth: '200px',
    },
    {
      name: 'ShadowPay',
      logo: shadowpay,
      className: 'shadowpay',
      logoWidth: '200px',
    },
    {
      name: 'Casino500',
      logo: csgo500,
      className: 'casino500',
      logoWidth: '100px',
    },
    {
      name: 'CSGOEmpire',
      logo: csgoempire,
      className: 'csgoempire',
      logoWidth: '200px',
    },
    {
      name: 'CSGORoll',
      logo: csgoorollico,
      className: 'csgo-roll',
      logoWidth: '150px',
    },
  ];

  return (
    <div className="marketplaces-section">
      <h2 className="marketplaces-title">Marketplaces We Support</h2>
      <div className="logos-container">
        {marketplaces.map((marketplace, index) => (
          <div
            className={`marketplace-item ${marketplace.className}`}
            key={index}
          >
            <img
              src={marketplace.logo}
              alt={`${marketplace.name} logo`}
              className={`marketplace-logo ${marketplace.className}-logo`}
              style={{ maxWidth: marketplace.logoWidth }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Marketplaces;
