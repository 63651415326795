// src/components/PolicyModal.jsx

import React from 'react';
import './PolicyModal.css';

const PolicyModal = ({ isOpen, onClose, title, children }) => {
  if (!isOpen) return null;

  return (
    <div className="policy-modal-overlay" onClick={onClose}>
      <div className="policy-modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="policy-modal-close-button" onClick={onClose}>
          &times;
        </button>
        <h2>{title}</h2>
        <div className="policy-modal-body">
          {children}
        </div>
      </div>
    </div>
  );
};

export default PolicyModal;
