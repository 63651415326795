// src/components/AccountsTable.js
import React, { useState, useEffect } from 'react';
import './AccountsTable.css'; // Ensure this file exists and contains necessary styles
import AddAccountForm from './AddAccountForm';

function AccountsTable({ accounts, onAccountSelect, onRefresh, setNotification, onDeleteAccount = () => {} }) {
  const [sortedAccounts, setSortedAccounts] = useState([]);
  const [sortConfig, setSortConfig] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [accountType, setAccountType] = useState('steam'); // Default to 'steam'

  // Search and Pagination States
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    const validAccounts = Array.isArray(accounts) ? accounts.filter(acc => acc) : [];
    setSortedAccounts(validAccounts);
  }, [accounts]);

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }

    const sortedData = [...sortedAccounts].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });

    setSortedAccounts(sortedData);
    setSortConfig({ key, direction });
  };

  const renderSortIcon = (key) => {
    if (!sortConfig || sortConfig.key !== key) return null;
    if (sortConfig.direction === 'ascending') {
      return <span>&uarr;</span>;
    }
    return <span>&darr;</span>;
  };

  const handleAddAccount = (newAccount) => {
    if (newAccount && newAccount.nickname && (newAccount.steamId || newAccount.steamAccountName)) {
      setSortedAccounts([...sortedAccounts, newAccount]);
      setNotification({ message: 'Account added successfully.', type: 'success' });
    } else {
      setNotification({ message: 'Invalid account data.', type: 'error' });
    }
  };

  const handleAccountClick = (account) => {
    if (isEditMode) {
      setSelectedAccount(account);
      setAccountType(account.steamAccountName ? 'steam' : 'non-steam'); // Determine account type based on presence of steamAccountName
      setShowForm(true);
    } else {
      onAccountSelect(account.nickname);
    }
  };

  const renderSteamStatus = (status) => {
    const steamStatuses = typeof status === 'object' && status !== null
      ? Object.keys(status).filter((key) => status[key] === true)
      : [];
      
    return steamStatuses.length > 0 ? steamStatuses.join(', ') : 'All Good';
  };

  const renderApiStatus = (apiErrors) => {
    if (apiErrors && apiErrors.length > 0) {
      return apiErrors.map(error => error.error).join('; ');
    }
    return 'All Good';
  };

  const handleRefresh = async () => {
    try {
      await onRefresh();
      setNotification({ message: 'Accounts refreshed successfully.', type: 'success' });
      setCurrentPage(1);
    } catch (error) {
      setNotification({ message: 'Error refreshing accounts.', type: 'error' });
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const filteredAccounts = sortedAccounts.filter(account =>
    (account?.nickname?.toLowerCase().includes(searchQuery.toLowerCase())) ||
    (account?.steamId?.toLowerCase().includes(searchQuery.toLowerCase())) ||
    (account?.steamAccountName?.toLowerCase().includes(searchQuery.toLowerCase()))
    // Add more fields to search if necessary
  );

  const totalPages = Math.ceil(filteredAccounts.length / itemsPerPage) || 1;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentAccounts = filteredAccounts.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const renderPagination = () => {
    if (totalPages <= 1) return null;

    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }

    return (
      <div className="pagination">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="pagination-btn"
        >
          &laquo; Prev
        </button>
        {pageNumbers.map(number => (
          <button
            key={number}
            onClick={() => handlePageChange(number)}
            className={`pagination-btn ${currentPage === number ? 'active' : ''}`}
          >
            {number}
          </button>
        ))}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="pagination-btn"
        >
          Next &raquo;
        </button>
      </div>
    );
  };

  const handleDeleteAccount = (nickname) => {
    const newSortedAccounts = sortedAccounts.filter(acc => acc.nickname !== nickname);

    if (newSortedAccounts.length < sortedAccounts.length) {
      setSortedAccounts(newSortedAccounts);
      onDeleteAccount(nickname);
      setNotification({ message: 'Account deleted successfully.', type: 'success' });

      const newFilteredAccounts = newSortedAccounts.filter(account =>
        (account?.nickname?.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (account?.steamId?.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (account?.steamAccountName?.toLowerCase().includes(searchQuery.toLowerCase()))
        // Add more fields to search if necessary
      );

      const newTotalPages = Math.ceil(newFilteredAccounts.length / itemsPerPage) || 1;

      if (currentPage > newTotalPages) {
        setCurrentPage(newTotalPages);
      }

      if (newTotalPages === 0) {
        setCurrentPage(1);
      }
    } else {
      setNotification({ message: 'Account not found.', type: 'error' });
    }
  };

  return (
    <div className={`table-container ${isEditMode ? 'edit-mode' : ''}`}>
      <div className="table-header">
        <h1>Accounts</h1>
        <div className="actions">
          {/* Search Bar */}
          <input
            type="text"
            className="search-bar"
            placeholder="Search accounts..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <button className="refresh-btn" onClick={handleRefresh}>
            Refresh
          </button>
          {/* Add Steam and Non-Steam Account Buttons */}
          <button
            className={`add-account-btn ${isEditMode ? 'disabled' : ''}`}
            onClick={() => {
              if (!isEditMode) {
                setAccountType('steam');
                setShowForm(true);
                setSelectedAccount(null);
              }
            }}
            disabled={isEditMode}
          >
            Add Steam Account
          </button>
          <button
            className={`add-account-btn ${isEditMode ? 'disabled' : ''}`}
            onClick={() => {
              if (!isEditMode) {
                setAccountType('non-steam');
                setShowForm(true);
                setSelectedAccount(null);
              }
            }}
            disabled={isEditMode}
          >
            Add Non-Steam Account
          </button>
          <button
            className="edit-delete-btn"
            onClick={() => setIsEditMode((prev) => !prev)}
          >
            {isEditMode ? 'Exit Edit/Delete Mode' : 'Edit/Delete Mode'}
          </button>
        </div>
      </div>
      <div className="table-wrapper">
        <table className="accounts-table">
          <thead>
            <tr>
              <th onClick={() => handleSort('nickname')}>
                Account Name {renderSortIcon('nickname')}
              </th>
              <th onClick={() => handleSort('steamId')}>
                Steam ID {renderSortIcon('steamId')}
              </th>
              <th onClick={() => handleSort('disputes')}>
                Disputes {renderSortIcon('disputes')}
              </th>
              <th onClick={() => handleSort('status')}>
                Status (Steam) {renderSortIcon('status')}
              </th>
              <th onClick={() => handleSort('apiErrors')}>
                Status (API's) {renderSortIcon('apiErrors')}
              </th>
              <th onClick={() => handleSort('banStatus')}>
                Ban Status {renderSortIcon('banStatus')}
              </th>
            </tr>
          </thead>
          <tbody>
            {currentAccounts.length > 0 ? (
              currentAccounts.map((account) => (
                <tr
                  key={account.nickname}
                  onClick={() => handleAccountClick(account)}
                  style={{ cursor: 'pointer' }}
                  className={isEditMode ? 'edit-mode' : ''}
                >
                  <td>{account.nickname || 'N/A'}</td>
                  <td>{account.steamId || account.steamAccountName || 'N/A'}</td>
                  <td>{account.disputes !== undefined ? account.disputes : '0'}</td>
                  <td>{renderSteamStatus(account.status)}</td>
                  <td>{renderApiStatus(account.apiErrors)}</td>
                  <td>{account.banStatus || 'N/A'}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="no-accounts">
                  <i className="fa fa-info-circle"></i> No accounts found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Pagination Controls */}
      {renderPagination()}

      {showForm && (
        <AddAccountForm
          onClose={() => setShowForm(false)}
          onAddAccount={handleAddAccount}
          accountToEdit={selectedAccount}
          isEditMode={isEditMode}
          onDeleteAccount={handleDeleteAccount}
          setNotification={setNotification}
          accountType={accountType} // Pass the account type
        />
      )}
    </div>
  );
}

export default AccountsTable;
