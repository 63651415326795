// src/components/ModalPopup.js

import React, { useEffect } from 'react';
import './ModalPopup.css';

function ModalPopup({ children, isOpen, onClose }) {
  useEffect(() => {
    if (isOpen) {
      // Prevent background scrolling when modal is open
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    // Cleanup on unmount
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  if (!isOpen) return null;

  // Handle Escape key to close modal
  const handleKeyDown = (e) => {
    if (e.key === 'Escape' && onClose) {
      onClose();
    }
  };

  return (
    <div
      className="app-modal-overlay"
      role="dialog"
      aria-modal="true"
      onKeyDown={handleKeyDown}
      tabIndex="-1"
    >
      <div className="app-modal-content">
        {onClose && (
          <button
            className="app-modal-close-btn"
            onClick={onClose}
            aria-label="Close Modal"
          >
            &times;
          </button>
        )}
        {children}
      </div>
    </div>
  );
}

export default ModalPopup;
