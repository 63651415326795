// buymodules/AccountSearch.js
import React from 'react';
import { FaCog } from 'react-icons/fa';
import './BuyWithSettings.css';

function AccountSearch({
    searchInput,
    handleSearchChange,
    showDropdown,
    filteredAccounts,
    handleAccountSelect,
    searchRef,
    setShowDropdown,
    // Settings props
    setSettingsDropdownVisible,
    settingsDropdownVisible,
}) {
    const handleSettingsClick = () => {
        setSettingsDropdownVisible((prev) => !prev);
    };

    return (
        <div className="search-and-account" ref={searchRef}>
            {/* Search Bar */}
            <input
                type="text"
                className="search-box"
                placeholder="Search Accounts..."
                value={searchInput}
                onChange={handleSearchChange}
                onFocus={() => setShowDropdown(true)}
                aria-label="Search Accounts"
            />
            {/* Settings Cog */}
            <FaCog
                className="buy-settings-cog"
                aria-label="Settings"
                onClick={handleSettingsClick}
                tabIndex="0"
                role="button"
                onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        handleSettingsClick();
                    }
                }}
            />

            {/* Account Search Dropdown */}
            {showDropdown && (
                <div className="account-search-dropdown">
                    {filteredAccounts.length > 0 ? (
                        filteredAccounts.map((nickname, index) => (
                            <div
                                key={index}
                                className="search-item"
                                onClick={() => handleAccountSelect(nickname)}
                                role="option"
                                tabIndex="0"
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        handleAccountSelect(nickname);
                                    }
                                }}
                            >
                                {nickname}
                            </div>
                        ))
                    ) : (
                        <div className="search-item no-accounts">No Accounts Found</div>
                    )}
                </div>
            )}
        </div>
    );
}

export default AccountSearch;
