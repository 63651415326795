// src/Homepagemodules/AddFundsModal.js

import React, { useState, useEffect } from 'react';
import './AddFundsModal.css'; // Ensure this path is correct
import litecoinLogo from '../pictures/litecoin.png'; // Import LiteCoin logo
import usdtLogo from '../pictures/usdt.png'; // Import USDT logo
import axios from 'axios'; // Import axios for HTTP requests

function AddFundsModal({ isOpen, onClose }) {
  const [selectedCurrency, setSelectedCurrency] = useState('LiteCoin');
  const [usdtNetwork, setUsdtNetwork] = useState('TRC20');
  const [amount, setAmount] = useState('');
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false); // To handle loading state

  // Close modal on Escape key press
  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEscape);
    } else {
      document.removeEventListener('keydown', handleEscape);
    }

    // Cleanup
    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [isOpen, onClose]);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate amount
    const numericAmount = parseFloat(amount);
    if (isNaN(numericAmount) || numericAmount < 10) {
      setError('Minimum top-up amount is $10.');
      return;
    }

    // Clear error and set loading state
    setError('');
    setIsSubmitting(true);

    // Prepare data to send to the server
    const topUpData = {
      currency: selectedCurrency,
      network: selectedCurrency === 'USDT' ? usdtNetwork : null,
      amount: numericAmount,
    };

    try {
      // Make a POST request to the deposit endpoint
      const response = await axios.post('/api/deposit', topUpData, {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true, // Include cookies if authentication relies on them
      });

      const { invoice_url } = response.data;

      if (invoice_url) {
        // Open the invoice URL in a new tab
        window.open(invoice_url, '_blank');
        onClose(); // Close the modal after opening the invoice
      } else {
        setError('Failed to retrieve invoice URL. Please try again.');
      }
    } catch (err) {
      console.error('Error during top-up:', err);
      if (err.response && err.response.data && err.response.data.message) {
        setError(err.response.data.message);
      } else {
        setError('An unexpected error occurred. Please try again.');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div
      className="afm-modal-overlay"
      onClick={onClose}
      aria-hidden={!isOpen}
    >
      <div
        className="afm-modal-content"
        onClick={(e) => e.stopPropagation()}
        role="dialog"
        aria-modal="true"
        aria-labelledby="afm-modal-title"
      >
        <h2 id="afm-modal-title">Add Funds</h2>
        <form onSubmit={handleSubmit}>
          <div className="afm-currency-selection">
            <div
              className={`afm-currency-box ${
                selectedCurrency === 'LiteCoin' ? 'afm-selected' : ''
              }`}
              onClick={() => setSelectedCurrency('LiteCoin')}
              role="button"
              tabIndex={0}
              onKeyPress={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  setSelectedCurrency('LiteCoin');
                }
              }}
            >
              <img src={litecoinLogo} alt="LiteCoin Logo" className="afm-currency-logo" />
              <span className="afm-currency-name">LiteCoin (LTC)</span>
            </div>
            <div
              className={`afm-currency-box ${
                selectedCurrency === 'USDT' ? 'afm-selected' : ''
              }`}
              onClick={() => setSelectedCurrency('USDT')}
              role="button"
              tabIndex={0}
              onKeyPress={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  setSelectedCurrency('USDT');
                }
              }}
            >
              <img src={usdtLogo} alt="USDT Logo" className="afm-currency-logo" />
              <span className="afm-currency-name">USDT</span>
            </div>
          </div>

          {selectedCurrency === 'USDT' && (
            <div className="afm-network-selection">
              <label htmlFor="afm-network">Select Network:</label>
              <select
                id="afm-network"
                value={usdtNetwork}
                onChange={(e) => setUsdtNetwork(e.target.value)}
              >
                <option value="TRC20">TRC20 (Fees ~ 3$)</option>
                <option value="BSC">BSC</option>
                <option value="MATIC">Polygon MATIC (Lowest Fees)</option>
                <option value="SOL">SOL</option>
              </select>
            </div>
          )}

          <div className="afm-amount-input">
            <label htmlFor="afm-amount">Top-Up Amount ($):</label>
            <input
              type="number"
              id="afm-amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              min="10"
              step="0.01"
              required
              placeholder="Enter amount in USD"
            />
          </div>

          {error && <p className="afm-error-message">{error}</p>}

          <div className="afm-modal-actions">
            <button type="submit" className="afm-submit-button" disabled={isSubmitting}>
              {isSubmitting ? 'Processing...' : 'Top Up'}
            </button>
            <button
              type="button"
              className="afm-close-button"
              onClick={onClose}
              disabled={isSubmitting}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddFundsModal;
