import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from './Homepagemodules/Navbar';
import Footer from './Homepagemodules/homepagefooter';
import NotificationSlider from './components/NotificationSlider';
import TransactionsTable from './transactionsmodules/TransactionsTable';
import './Transactions.css';

function Transactions() {
    // Existing state variables
    const [loading, setLoading] = useState(true);
    const [authenticated, setAuthenticated] = useState(false);
    const [transactionsCache, setTransactionsCache] = useState([]); // Stores fetched transactions in batches of 100
    const [notification, setNotification] = useState({ message: '', type: '' });
    const [filters, setFilters] = useState({});
    const [currentClientPage, setCurrentClientPage] = useState(1); // Tracks client-side page (50 per page)
    const [totalClientPages, setTotalClientPages] = useState(1); // Total client-side pages

    const navigate = useNavigate();
    const hasFetchedAuth = useRef(false);

    const transactionsPerServerFetch = 100; // Number of transactions fetched per server request
    const transactionsPerClientPage = 50; // Number of transactions displayed per client page

    const [currentServerPage, setCurrentServerPage] = useState(1); // Tracks server-side page
    const [totalServerPages, setTotalServerPages] = useState(1); // Tracks total server-side pages

    /**
     * 1. Authentication Check
     */
    useEffect(() => {
        const checkAuth = async () => {
            try {
                if (hasFetchedAuth.current) return;
                hasFetchedAuth.current = true;

                const response = await fetch('/api/auth/isAuthenticated', {
                    credentials: 'include',
                });

                if (response.ok) {
                    setAuthenticated(true);
                } else {
                    navigate('/');
                }
            } catch (error) {
                console.error('Error checking authentication:', error);
                navigate('/');
            } finally {
                setLoading(false);
            }
        };

        checkAuth();
    }, [navigate]);

    /**
     * 2. Fetch Transactions Data with Filters and Server Pagination
     */
    useEffect(() => {
        const fetchTransactions = async () => {
            try {
                if (!authenticated) return;

                // Build query parameters based on filters and server pagination
                const queryParams = new URLSearchParams();

                if (filters.nickname) queryParams.append('nickname', filters.nickname);
                if (filters.operationtype) queryParams.append('operationtype', filters.operationtype);
                if (filters.marketplace) queryParams.append('marketplace', filters.marketplace);
                if (filters.dateFrom) queryParams.append('dateFrom', filters.dateFrom);
                if (filters.dateTo) queryParams.append('dateTo', filters.dateTo);

                // Server-side pagination parameters
                queryParams.append('page', currentServerPage);
                queryParams.append('limit', transactionsPerServerFetch); // Fetch 100 per server request

                const queryString = `?${queryParams.toString()}`;

                const response = await fetch(`/api/transactions${queryString}`, {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setTransactionsCache(prev => [...prev, ...data.transactions]); // Append fetched transactions
                    setTotalServerPages(data.totalPages);
                    setTotalClientPages(prev => prev + Math.ceil(data.transactions.length / transactionsPerClientPage));
                } else {
                    const errorData = await response.json();
                    setNotification({
                        message: errorData.message || 'Failed to fetch transactions',
                        type: 'error',
                    });
                    console.error('Failed to fetch transactions:', errorData.message);
                }
            } catch (error) {
                console.error('Error fetching transactions:', error);
                setNotification({
                    message: 'Error fetching transactions',
                    type: 'error',
                });
            }
        };

        fetchTransactions();
    }, [authenticated, filters, currentServerPage]);

    /**
     * 3. Handle Filter Application
     */
    const handleFilterApplied = (appliedFilters) => {
        setFilters(appliedFilters);
        setCurrentClientPage(1);
        setCurrentServerPage(1);
        setTransactionsCache([]);
        setTotalClientPages(1);
    };

    /**
     * 4. Handle Page Change
     */
    const handlePageChange = (newPage) => {
        if (newPage < 1) return;

        // Calculate the maximum client page based on fetched transactions
        const maxClientPage = Math.ceil(transactionsCache.length / transactionsPerClientPage);

        if (newPage > maxClientPage && currentServerPage < totalServerPages) {
            // Fetch next server page
            setCurrentServerPage(prev => prev + 1);
        }

        setCurrentClientPage(newPage);
    };

    /**
     * 5. Determine Transactions to Display Based on Current Client Page
     */
    const getCurrentTransactions = () => {
        const startIndex = (currentClientPage - 1) * transactionsPerClientPage;
        const endIndex = startIndex + transactionsPerClientPage;
        return transactionsCache.slice(0, currentServerPage * transactionsPerServerFetch).slice(startIndex, endIndex);
    };

    /**
     * 6. Render Loading State
     */
    if (loading) {
        return (
            <div className="loading-screen">
                <div className="loading-spinner"></div>
            </div>
        );
    }

    /**
     * 7. Render If Not Authenticated
     */
    if (!authenticated) {
        return null;
    }

    /**
     * 8. Render Transactions Page
     */
    return (
        <div className="transactions-page">
            {/* Navbar with authentication props */}
            <Navbar authenticated={authenticated} setAuthenticated={setAuthenticated} />

            {/* Notification Slider for messages */}
            <NotificationSlider message={notification.message} type={notification.type} />

            {/* Transactions Content */}
            <div className="transactions-content">
                <h2 className="section-heading">Transactions</h2>

                <TransactionsTable
                    transactions={getCurrentTransactions()}
                    onFilterApplied={handleFilterApplied}
                />

                {/* Pagination Controls */}
                <div className="pagination-controls">
                    <button
                        onClick={() => handlePageChange(currentClientPage - 1)}
                        disabled={currentClientPage === 1}
                    >
                        Previous
                    </button>
                    <span>
                        Page {currentClientPage} of {Math.ceil(transactionsCache.length / transactionsPerClientPage)}
                    </span>
                    <button
                        onClick={() => handlePageChange(currentClientPage + 1)}
                        disabled={currentClientPage * transactionsPerClientPage >= transactionsCache.length && currentServerPage >= totalServerPages}
                    >
                        Next
                    </button>
                </div>
            </div>

            {/* Footer */}
            <Footer />
        </div>
    );
}

export default Transactions;
