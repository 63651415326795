import React from 'react';
import { FaCog } from 'react-icons/fa';
import './DashboardHeader.css';

function DashboardHeader({ toggleSettingsPanel }) {
  return (
    <div className="dashboard-header">
      <h1 className="dashboard-heading">Dashboard</h1>
      <FaCog className="settings-icon" onClick={toggleSettingsPanel} />
    </div>
  );
}

export default DashboardHeader;
