

import React from 'react';

const TermsOfService = () => (
  <div>
    <h3>Terms of Service</h3>
    <p><strong>Last Updated:</strong> 01/12/2024</p>

    <h4>1. Introduction</h4>
    <p>
      Welcome to MasterPanel LLC ("MasterPanel," "we," "our," or "us"). These Terms of Service ("Terms") govern your use of our services, including our website and any related products or services (collectively, the "Service"). By accessing or using our Service, you agree to comply with and be bound by these Terms.
    </p>

    <h4>2. Acceptance of Terms</h4>
    <p>
      By accessing or using the Service, you affirm that you are at least 18 years old and capable of forming a binding contract. If you do not agree to these Terms, you must not use our Service.
    </p>

    <h4>3. Services Provided</h4>
    <p>
      MasterPanel offers automation tools designed to assist users in managing and automating tasks across various marketplaces. The specifics of our services are detailed on our website and within the Service interface.
    </p>

    <h4>4. Account Registration and Security</h4>
    <p><strong>Account Creation:</strong> To access certain features of the Service, you may need to create an account. You agree to provide accurate, current, and complete information during registration and to update such information to keep it accurate.</p>
    <p><strong>Account Security:</strong> You are responsible for maintaining the confidentiality of your account credentials and are liable for all activities that occur under your account.</p>

    <h4>5. Payment Terms</h4>
    <p><strong>Fees:</strong> Access to the Service may require payment of fees as outlined on our pricing page. All fees are non-refundable.</p>
    <p><strong>Payment Processing:</strong> Payments are processed through third-party payment processors. By providing payment information, you authorize us to charge the applicable fees to your chosen payment method.</p>

    <h4>6. No Refund Policy</h4>
    <p>
      All funds deposited into your MasterPanel account are non-refundable under any circumstances. By using our Service, you acknowledge and agree that MasterPanel does not offer refunds for any reason.
    </p>

    <h4>7. Limitation of Liability</h4>
    <p>
      To the fullest extent permitted by law, MasterPanel shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages, including but not limited to financial losses, arising out of your use of the Service. You agree that MasterPanel is not responsible for any financial loss incurred due to the use of our panel.
    </p>

    <h4>8. Indemnification</h4>
    <p>
      You agree to indemnify, defend, and hold harmless MasterPanel, its affiliates, officers, directors, employees, and agents from and against any and all claims, liabilities, damages, losses, and expenses arising out of or in any way connected with your use of the Service or violation of these Terms.
    </p>

    <h4>9. Governing Law</h4>
    <p>
      These Terms shall be governed by and construed in accordance with the laws of the Commonwealth of Kentucky, without regard to its conflict of law principles. Any legal actions or proceedings arising out of or relating to these Terms or your use of the Service shall be brought exclusively in the state or federal courts located in Kentucky.
    </p>

    <h4>10. Changes to Terms</h4>
    <p>
      MasterPanel reserves the right to modify or replace these Terms at any time. We will provide notice of significant changes by updating the "Last Updated" date. Your continued use of the Service following any changes constitutes acceptance of the new Terms.
    </p>

    <h4>11. Termination</h4>
    <p>
      We may terminate or suspend your access to the Service immediately, without prior notice or liability, for any reason, including if you breach these Terms. Upon termination, all rights granted to you under these Terms will cease immediately.
    </p>

    <h4>12. Contact Us</h4>
    <p>
      If you have any questions about these Terms, please contact us at:
    </p>
    <p>
      MasterPanel LLC<br />
      6844 Bardstown Rd #5052, Louisville, KY 40291
      40291
      Miami
      United States<br />
      support@masterspanel.com<br />
    </p>
  </div>
);

export default TermsOfService;
