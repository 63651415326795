// SearchWithSettings.js
import React from 'react';
import { FaCog } from 'react-icons/fa';
import './SearchWithSettings.css'; // Ensure this CSS file is correctly linked

function SearchWithSettings({
    value,
    onChange,
    onFocus,
    placeholder,
    showDropdown,
    children, // Dropdown content
    ariaLabel,
    onSettingsClick, // Handler for settings cog click
}) {
    return (
        <div className="search-with-settings">
            {/* Search Input */}
            <input
                type="text"
                className="search-box"
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                onFocus={onFocus}
                aria-label={ariaLabel}
            />

            {/* Settings Cog */}
            <FaCog
                className="settings-cog"
                aria-label="Settings"
                onClick={onSettingsClick}
                tabIndex="0"
                role="button"
                onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        onSettingsClick();
                    }
                }}
            />

            {/* Dropdown */}
            {showDropdown && (
                <div className="account-search-dropdown">
                    {children}
                </div>
            )}
        </div>
    );
}

export default SearchWithSettings;
