// SettingsMenu.js
import React from 'react';
import './SettingsMenu.css'; // Ensure this CSS file is correctly linked

function SettingsMenu({ context, selectedAccount, modifiers, onModifierChange }) {
    const markets = [
        { name: 'Market.CSGO', saleKey: 'marketsale', undercutKey: 'marketundercut' },
        { name: 'CSGOEmpire', saleKey: 'csgoempiresale', undercutKey: 'csgoempireundercut' },
        { name: 'Waxpeer', saleKey: 'waxpeersale', undercutKey: 'waxpeerundercut' },
        { name: 'CSGO500', saleKey: 'csgo500sale', undercutKey: 'csgo500undercut' },
        { name: 'ShadowPay', saleKey: 'shadowpaysale', undercutKey: 'shadowpayundercut' },
        // Add other markets as needed
    ];

    return (
        <div className="settings-menu">
            <h3>{context === 'undercutting' ? 'Undercutting Settings' : 'Sale Settings'}</h3>
            <div className="markets-container">
                {markets.map((market) => (
                    <div className="market-item" key={market.saleKey}>
                        <span className="market-name">{market.name}</span>
                        
                        {/* Sale Modifier Input */}
                        {context !== 'undercutting' && (
                            <div className="modifier-group">
                                <label htmlFor={`${market.saleKey}`}>
                                    Sale Modifier (%)
                                </label>
                                <input
                                    type="number"
                                    min="0"
                                    max="200"
                                    id={`${market.saleKey}`}
                                    value={modifiers[market.saleKey] || ''}
                                    onChange={(e) => onModifierChange(market.saleKey, e.target.value)}
                                    placeholder="Sale Modifier (%)"
                                    aria-label={`Sale modifier for ${market.name}`}
                                />
                            </div>
                        )}

                        {/* Undercut Modifier Input */}
                        {context === 'undercutting' && (
                            <div className="modifier-group">
                                <label htmlFor={`${market.undercutKey}`}>
                                     Undercut Modifier
                                </label>
                                <input
                                    type="number"
                                    min="0"
                                    max="200"
                                    id={`${market.undercutKey}`}
                                    value={modifiers[market.undercutKey] || ''}
                                    onChange={(e) => onModifierChange(market.undercutKey, e.target.value)}
                                    placeholder="Undercut Modifier (%)"
                                    aria-label={`Undercut modifier for ${market.name}`}
                                />
                            </div>
                        )}
                    </div>
                ))}
            </div>
            {selectedAccount ? (
                <p className="selected-account">Account: {selectedAccount}</p>
            ) : (
                <p className="global-tag">Global Settings</p>
            )}
        </div>
    );
}

export default SettingsMenu;
