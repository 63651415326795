// src/components/SteamAccountForm.js

import React, { useState, useEffect } from 'react';
import './SteamAccountForm.css';

function SteamAccountForm({
  formData,
  setFormData,
  editableFields,
  toggleEditField,
  isEditMode,
  setNotification,
  openCsgoEmpireConfirm, // Function to open confirmation modal
  isCsgoEmpireEnabled // Boolean indicating if CSGOEmpire is enabled
}) {
  const {
    nickname,
    steamAccountName,
    steamPassword,
    steamIdentitySecret,
    steamSharedSecret,
    proxy,
    // Toggles for services
    registerMarketCsgo,
    registerWaxpeer,
    registerShadowpay,
    registerCsgoEmpire,
    // Extra CSGOEmpire fields
    csgoEmpireUsername,
    csgoEmpireEmail,
    csgoEmpirePassword,
    csgoEmpireApiKey,
    csgo500UserId,
    csgo500ApiKey
  } = formData;

  // State to manage password validation errors
  const [passwordError, setPasswordError] = useState('');

  // Regular expression for password validation
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;

  // Function to validate password in real-time
  const validatePassword = (password) => {
    if (passwordRegex.test(password)) {
      setPasswordError('');
      return true;
    } else {
      setPasswordError(
        'Password must be at least 8 characters long and include at least 1 number, 1 uppercase letter, and 1 lowercase letter.'
      );
      return false;
    }
  };

  // useEffect to validate password whenever it changes
  useEffect(() => {
    if (registerCsgoEmpire && csgoEmpirePassword) {
      validatePassword(csgoEmpirePassword);
    } else {
      setPasswordError('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [csgoEmpirePassword, registerCsgoEmpire]);

  const handleToggleCsgoEmpire = (e) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      // Open confirmation popup before enabling
      openCsgoEmpireConfirm();
    } else {
      // If unchecking, simply disable the CSGOEmpire registration
      setFormData({ ...formData, registerCsgoEmpire: false });
      setPasswordError('');
    }
  };

  return (
    <div className="steam-account-form">
      {/* Row 1: Nickname */}
      <div className="steam-form-row">
        <div className="steam-form-group">
          <label htmlFor="nickname">Nickname (required)</label>
          <input
            type="text"
            id="nickname"
            name="nickname"
            value={nickname}
            onChange={(e) => setFormData({ ...formData, nickname: e.target.value })}
            required
            readOnly={isEditMode} // Read-only in edit mode
          />
        </div>
      </div>

      {/* Row 2: Steam Account Name & Password */}
      <div className="steam-form-row">
        <div className="steam-form-group">
          <label htmlFor="steamAccountName">Steam Account Name</label>
          <input
            type="text"
            id="steamAccountName"
            name="steamAccountName"
            value={steamAccountName}
            onChange={(e) => setFormData({ ...formData, steamAccountName: e.target.value })}
            disabled={!editableFields.steamAccountName}
          />
          {isEditMode && (
            <button
              type="button"
              className="steam-edit-btn"
              onClick={() => toggleEditField('steamAccountName')}
              aria-label="Toggle edit for Steam Account Name"
            >
              {editableFields.steamAccountName ? 'Lock' : 'Edit'}
            </button>
          )}
        </div>
        <div className="steam-form-group">
          <label htmlFor="steamPassword">Steam Password</label>
          <input
            type="password"
            id="steamPassword"
            name="steamPassword"
            value={steamPassword}
            onChange={(e) => setFormData({ ...formData, steamPassword: e.target.value })}
            disabled={!editableFields.steamPassword}
          />
          {isEditMode && (
            <button
              type="button"
              className="steam-edit-btn"
              onClick={() => toggleEditField('steamPassword')}
              aria-label="Toggle edit for Steam Password"
            >
              {editableFields.steamPassword ? 'Lock' : 'Edit'}
            </button>
          )}
        </div>
      </div>

      {/* Row 3: Steam Identity Secret & Shared Secret */}
      <div className="steam-form-row">
        <div className="steam-form-group">
          <label htmlFor="steamIdentitySecret">Steam Identity Secret</label>
          <input
            type="text"
            id="steamIdentitySecret"
            name="steamIdentitySecret"
            value={steamIdentitySecret}
            onChange={(e) => setFormData({ ...formData, steamIdentitySecret: e.target.value })}
            disabled={!editableFields.steamIdentitySecret}
          />
          {isEditMode && (
            <button
              type="button"
              className="steam-edit-btn"
              onClick={() => toggleEditField('steamIdentitySecret')}
              aria-label="Toggle edit for Steam Identity Secret"
            >
              {editableFields.steamIdentitySecret ? 'Lock' : 'Edit'}
            </button>
          )}
        </div>
        <div className="steam-form-group">
          <label htmlFor="steamSharedSecret">Steam Shared Secret</label>
          <input
            type="text"
            id="steamSharedSecret"
            name="steamSharedSecret"
            value={steamSharedSecret}
            onChange={(e) => setFormData({ ...formData, steamSharedSecret: e.target.value })}
            disabled={!editableFields.steamSharedSecret}
          />
          {isEditMode && (
            <button
              type="button"
              className="steam-edit-btn"
              onClick={() => toggleEditField('steamSharedSecret')}
              aria-label="Toggle edit for Steam Shared Secret"
            >
              {editableFields.steamSharedSecret ? 'Lock' : 'Edit'}
            </button>
          )}
        </div>
      </div>

      {/* Row 4: Proxy */}
      <div className="steam-form-row">
        <div className="steam-form-group">
          <label htmlFor="proxy">Proxy (required)</label>
          <input
            type="text"
            id="proxy"
            name="proxy"
            value={proxy}
            onChange={(e) => setFormData({ ...formData, proxy: e.target.value })}
            placeholder="http://login:pass@IP:port"
            required
            disabled={!editableFields.proxy}
          />
          {isEditMode && (
            <button
              type="button"
              className="steam-edit-btn"
              onClick={() => toggleEditField('proxy')}
              aria-label="Toggle edit for Proxy"
            >
              {editableFields.proxy ? 'Lock' : 'Edit'}
            </button>
          )}
        </div>
      </div>

      {/* Service Toggles */}
      <div className="steam-form-row steam-toggles-row">
        <div className="steam-form-group">
          <label className="steam-custom-checkbox">
            <input
              type="checkbox"
              checked={registerMarketCsgo || false}
              onChange={(e) =>
                setFormData({ ...formData, registerMarketCsgo: e.target.checked })
              }
            />
            <span className="steam-checkmark"></span>
            Enable market.csgo
          </label>
        </div>
        <div className="steam-form-group">
          <label className="steam-custom-checkbox">
            <input
              type="checkbox"
              checked={registerWaxpeer || false}
              onChange={(e) =>
                setFormData({ ...formData, registerWaxpeer: e.target.checked })
              }
            />
            <span className="steam-checkmark"></span>
            Enable Waxpeer
          </label>
        </div>
        <div className="steam-form-group">
          <label className="steam-custom-checkbox">
            <input
              type="checkbox"
              checked={registerShadowpay || false}
              onChange={(e) =>
                setFormData({ ...formData, registerShadowpay: e.target.checked })
              }
            />
            <span className="steam-checkmark"></span>
            Enable Shadowpay
          </label>
        </div>
        <div className="steam-form-group">
          <label className="steam-custom-checkbox">
            <input
              type="checkbox"
              checked={registerCsgoEmpire || false}
              onChange={handleToggleCsgoEmpire}
            />
            <span className="steam-checkmark"></span>
            Enable CSGOEmpire
          </label>
        </div>
      </div>

      {/* CSGOEmpire Username, Email, and Password Fields (Shown Only When Enabled) */}
      {registerCsgoEmpire && (
        <div className="steam-form-row steam-csgoempire-fields">
          {/* CSGOEmpire Username */}
          <div className="steam-form-group">
            <label htmlFor="csgoEmpireUsername">CSGOEmpire Username</label>
            <input
              type="text"
              id="csgoEmpireUsername"
              name="csgoEmpireUsername"
              value={csgoEmpireUsername}
              onChange={(e) =>
                setFormData({ ...formData, csgoEmpireUsername: e.target.value })
              }
              required={registerCsgoEmpire}
              disabled={!editableFields.csgoEmpireUsername}
            />
            {isEditMode && (
              <button
                type="button"
                className="steam-edit-btn"
                onClick={() => toggleEditField('csgoEmpireUsername')}
                aria-label="Toggle edit for CSGOEmpire Username"
              >
                {editableFields.csgoEmpireUsername ? 'Lock' : 'Edit'}
              </button>
            )}
          </div>

          {/* CSGOEmpire Email */}
          <div className="steam-form-group">
            <label htmlFor="csgoEmpireEmail">CSGOEmpire Email</label>
            <input
              type="email"
              id="csgoEmpireEmail"
              name="csgoEmpireEmail"
              value={csgoEmpireEmail}
              onChange={(e) =>
                setFormData({ ...formData, csgoEmpireEmail: e.target.value })
              }
              required={registerCsgoEmpire}
              disabled={!editableFields.csgoEmpireEmail}
            />
            {isEditMode && (
              <button
                type="button"
                className="steam-edit-btn"
                onClick={() => toggleEditField('csgoEmpireEmail')}
                aria-label="Toggle edit for CSGOEmpire Email"
              >
                {editableFields.csgoEmpireEmail ? 'Lock' : 'Edit'}
              </button>
            )}
          </div>

          {/* CSGOEmpire Password */}
          <div className="steam-form-group">
            <label htmlFor="csgoEmpirePassword">CSGOEmpire Password</label>
            <input
              type="password"
              id="csgoEmpirePassword"
              name="csgoEmpirePassword"
              value={csgoEmpirePassword}
              onChange={(e) =>
                setFormData({ ...formData, csgoEmpirePassword: e.target.value })
              }
              required={registerCsgoEmpire}
              disabled={!editableFields.csgoEmpirePassword}
              className={passwordError ? 'steam-input-error' : ''}
            />
            {passwordError && <p className="steam-error-text">{passwordError}</p>}
            {isEditMode && (
              <button
                type="button"
                className="steam-edit-btn"
                onClick={() => toggleEditField('csgoEmpirePassword')}
                aria-label="Toggle edit for CSGOEmpire Password"
              >
                {editableFields.csgoEmpirePassword ? 'Lock' : 'Edit'}
              </button>
            )}
          </div>
        </div>
      )}

      {/* Always Visible API Fields */}
      <div className="steam-form-row">
        {/* CSGOEmpire API Key */}
        <div className="steam-form-group">
          <label htmlFor="csgoEmpireApiKey">CSGOEmpire API Key</label>
          <input
            type="text"
            id="csgoEmpireApiKey"
            name="csgoEmpireApiKey"
            value={csgoEmpireApiKey}
            onChange={(e) =>
              setFormData({ ...formData, csgoEmpireApiKey: e.target.value })
            }
            disabled={isCsgoEmpireEnabled} // Greyed out when enabled
          />
        </div>

        {/* CSGO500 API Key */}
        <div className="steam-form-group">
          <label htmlFor="csgo500ApiKey">CSGO500 API Key</label>
          <input
            type="text"
            id="csgo500ApiKey"
            name="csgo500ApiKey"
            value={csgo500ApiKey}
            onChange={(e) =>
              setFormData({ ...formData, csgo500ApiKey: e.target.value })
            }
            disabled={false} // Always editable
          />
        </div>

        {/* CSGO500 User ID */}
        <div className="steam-form-group">
          <label htmlFor="csgo500UserId">CSGO500 User ID</label>
          <input
            type="text"
            id="csgo500UserId"
            name="csgo500UserId"
            value={csgo500UserId}
            onChange={(e) =>
              setFormData({ ...formData, csgo500UserId: e.target.value })
            }
            disabled={false} // Always editable
          />
        </div>
      </div>
    </div>
  );
}

export default SteamAccountForm;
