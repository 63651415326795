// src/components/AddAccountForm.js

import React, { useState, useEffect } from 'react';
import './AddAccountForm.css';
import SteamAccountForm from './SteamAccountForm';
import NonSteamAccountForm from './NonSteamAccountForm';
import ModalPopup from './ModalPopup';
import InlineLoadingSpinner from './InlineLoadingSpinner';
import './Tabs.css'; // Import the new tabs CSS

function AddAccountForm({
  onClose,
  onAddAccount,
  accountToEdit = null,
  isEditMode = false,
  onDeleteAccount,
  setNotification,
  accountType = 'steam' // Default to 'steam'
}) {
  const [loading, setLoading] = useState(false);
  const [verificationPopupOpen, setVerificationPopupOpen] = useState(false);
  const [verificationId, setVerificationId] = useState(null);
  const [verificationLoading, setVerificationLoading] = useState(false);
  const [verificationError, setVerificationError] = useState(null);

  const initialFormData = {
    nickname: '',
    // Steam-specific fields
    steamAccountName: '',
    steamPassword: '',
    steamIdentitySecret: '',
    steamSharedSecret: '',
    proxy: '',
    // Toggles
    registerMarketCsgo: false,
    registerWaxpeer: false,
    registerShadowpay: false,
    registerCsgoEmpire: false,
    // CSGOEmpire fields
    csgoEmpireUsername: '',
    csgoEmpireEmail: '',
    csgoEmpirePassword: '',
    // Non-Steam-specific fields
    waxpeerApiKey: '',
    shadowpayApiKey: '',
    shadowpayWssToken: '',
    marketCsgoApiKey: '',
    csgoEmpireApiKey: '',
    CSGORollPassword: '',
    CSGORollEmailId: '',
    csgo500ApiKey: '',
    csgo500UserId: ''
  };

  const [formData, setFormData] = useState(initialFormData);

  const [editableFields, setEditableFields] = useState({
    steamAccountName: !isEditMode,
    steamPassword: !isEditMode,
    steamIdentitySecret: !isEditMode,
    steamSharedSecret: !isEditMode,
    proxy: !isEditMode,
    // Toggles should be editable
    registerMarketCsgo: !isEditMode,
    registerWaxpeer: !isEditMode,
    registerShadowpay: !isEditMode,
    registerCsgoEmpire: !isEditMode,
    // CSGOEmpire fields
    csgoEmpireUsername: !isEditMode,
    csgoEmpireEmail: !isEditMode,
    csgoEmpirePassword: !isEditMode,
    // Non-Steam-specific fields
    waxpeerApiKey: !isEditMode,
    shadowpayApiKey: !isEditMode,
    shadowpayWssToken: !isEditMode,
    marketCsgoApiKey: !isEditMode,
    csgoEmpireApiKey: !isEditMode,
    CSGORollPassword: !isEditMode,
    CSGORollEmailId: !isEditMode,
    csgo500ApiKey: !isEditMode,
    csgo500UserId: !isEditMode
  });

  useEffect(() => {
    if (isEditMode && accountToEdit) {
      setFormData({
        nickname: accountToEdit.nickname || '',
        steamAccountName: accountToEdit.steamAccountName || '',
        steamPassword: accountToEdit.steamPassword || '',
        steamIdentitySecret: accountToEdit.steamIdentitySecret || '',
        steamSharedSecret: accountToEdit.steamSharedSecret || '',
        proxy: accountToEdit.proxy || '',
        // Toggles
        registerMarketCsgo: accountToEdit.registerMarketCsgo || false,
        registerWaxpeer: accountToEdit.registerWaxpeer || false,
        registerShadowpay: accountToEdit.registerShadowpay || false,
        registerCsgoEmpire: accountToEdit.registerCsgoEmpire || false,
        // CSGOEmpire fields
        csgoEmpireUsername: accountToEdit.csgoEmpireUsername || '',
        csgoEmpireEmail: accountToEdit.csgoEmpireEmail || '',
        csgoEmpirePassword: accountToEdit.csgoEmpirePassword || '',
        // Non-Steam-specific fields
        waxpeerApiKey: accountToEdit.waxpeerApiKey || '',
        shadowpayApiKey: accountToEdit.shadowpayApiKey || '',
        shadowpayWssToken: accountToEdit.shadowpayWssToken || '',
        marketCsgoApiKey: accountToEdit.marketCsgoApiKey || '',
        csgoEmpireApiKey: accountToEdit.csgoEmpireApiKey || '',
        CSGORollPassword: accountToEdit.CSGORollPassword || '',
        CSGORollEmailId: accountToEdit.CSGORollEmailId || '',
        csgo500ApiKey: accountToEdit.csgo500ApiKey || '',
        csgo500UserId: accountToEdit.csgo500UserId || ''
      });
    }
  }, [isEditMode, accountToEdit]);

  const toggleEditField = (field) => {
    setEditableFields((prevFields) => ({
      ...prevFields,
      [field]: !prevFields[field]
    }));
  };

  const validateProxy = (proxy) => {
    const proxyPattern = /^http:\/\/\w+:\w+@\d{1,3}(\.\d{1,3}){3}:\d+$/;
    return proxyPattern.test(proxy);
  };

  // New state for CSGOEmpire confirmation popup
  const [csgoEmpireConfirmOpen, setCsgoEmpireConfirmOpen] = useState(false);
  const [pendingCsgoEmpireEnable, setPendingCsgoEmpireEnable] = useState(false);

  const handleCsgoEmpireConfirmation = (confirm) => {
    if (confirm) {
      setFormData({ ...formData, registerCsgoEmpire: true });
      // Optionally, perform any additional actions when confirmed
    } else {
      // If the user cancels, ensure the checkbox remains unchecked
      setFormData({ ...formData, registerCsgoEmpire: false });
    }
    setCsgoEmpireConfirmOpen(false);
    setPendingCsgoEmpireEnable(false);
  };

  const [activeTab, setActiveTab] = useState('steam');

  useEffect(() => {
    if (isEditMode) {
      setActiveTab('steam');
    } else {
      setActiveTab(accountType === 'steam' ? 'steam' : 'non-steam');
    }
  }, [isEditMode, accountType]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setVerificationError(null); // Reset any previous verification errors

    let updatedFormData = { ...formData }; // Clone formData to modify

    if (isEditMode) {
      // Handle Steam-specific fields
      const steamFieldsToInclude = [
        'steamAccountName',
        'steamPassword',
        'steamIdentitySecret',
        'steamSharedSecret',
        'proxy',
        'registerMarketCsgo',
        'registerWaxpeer',
        'registerShadowpay',
        'registerCsgoEmpire'
      ];

      if (formData.registerCsgoEmpire) {
        steamFieldsToInclude.push('csgoEmpireUsername', 'csgoEmpireEmail', 'csgoEmpirePassword');
      }

      steamFieldsToInclude.forEach((field) => {
        const value = formData[field];
        const isEditable = editableFields[field];
      
        if (isEditable) {
          // If the field is editable, assign the value directly
          updatedFormData[field] = value;
        } else {
          if (typeof value === 'string') {
            // For string fields, apply trim and handle empty strings
            updatedFormData[field] = value.trim() !== '' ? value.trim() : 'NotEdited';
          } else if (typeof value === 'boolean') {
            // For boolean fields, assign the value directly or handle as needed
            updatedFormData[field] = value;
          } else {
            // For other types, handle accordingly (e.g., numbers, objects)
            // Here, as an example, we assign 'NotEdited' if the value is falsy
            updatedFormData[field] = value ? value : 'NotEdited';
          }
        }
      });
      

      // Handle Non-Steam-specific fields
      const nonSteamFieldsToInclude = [
        'waxpeerApiKey',
        'shadowpayApiKey',
        'shadowpayWssToken',
        'marketCsgoApiKey',
        'csgoEmpireApiKey',
        'CSGORollPassword',
        'CSGORollEmailId',
        'csgo500ApiKey',
        'csgo500UserId',
        'proxy' // Include proxy if needed
      ];

      nonSteamFieldsToInclude.forEach((field) => {
        updatedFormData[field] = editableFields[field]
          ? formData[field]
          : formData[field].trim() !== ''
          ? formData[field]
          : 'NotEdited';
      });
    } else {
      // Non-edit mode (Adding a new account)
      updatedFormData = { ...formData };

      /*if (accountType === 'non-steam' && formData.steamId.trim() === '') {
        setNotification({
          message: 'SteamID is required for non-Steam accounts when adding a new account.',
          type: 'error'
        });
        setLoading(false);
        return;
      }*/

      if (accountType !== 'non-steam') {
        delete updatedFormData.steamId; // Ensure steamId is not sent for Steam accounts
      }
    }

    // **Validation Logic Based on Account Type**
    // Adjusted for both account types in edit mode

    // Steam Validations
    if (formData.steamAccountName || formData.steamPassword || formData.steamIdentitySecret || formData.steamSharedSecret) {
      if (
        !formData.steamAccountName ||
        !formData.steamPassword ||
        !formData.steamIdentitySecret ||
        !formData.steamSharedSecret
      ) {
        setNotification({
          message:
            'All Steam details (Account Name, Password, Identity Secret, Shared Secret) are required.',
          type: 'error'
        });
        setLoading(false);
        return;
      }

      if (formData.proxy && !validateProxy(formData.proxy)) {
        setNotification({
          message: 'Proxy must be in the format http://login:pass@IP:port.',
          type: 'error'
        });
        setLoading(false);
        return;
      }

      if (formData.registerCsgoEmpire) {
        if (!formData.csgoEmpireUsername || !formData.csgoEmpireEmail || !formData.csgoEmpirePassword) {
          setNotification({
            message: 'CSGOEmpire Username, Email, and Password are required when enabled.',
            type: 'error'
          });
          setLoading(false);
          return;
        }

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(formData.csgoEmpireEmail)) {
          setNotification({
            message: 'Please enter a valid email address for CSGOEmpire.',
            type: 'error'
          });
          setLoading(false);
          return;
        }

        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
        if (!passwordRegex.test(formData.csgoEmpirePassword)) {
          setNotification({
            message:
              'CSGOEmpire Password must be at least 8 characters long and include at least 1 number, 1 uppercase letter, and 1 lowercase letter.',
            type: 'error'
          });
          setLoading(false);
          return;
        }
      }
    }

    // Non-Steam Validations
    if (formData.waxpeerApiKey || formData.shadowpayApiKey || formData.shadowpayWssToken || formData.marketCsgoApiKey || formData.csgoEmpireApiKey || formData.CSGORollPassword || formData.CSGORollEmailId || formData.csgo500ApiKey || formData.csgo500UserId) {
      if (
        !formData.waxpeerApiKey ||
        !formData.shadowpayApiKey ||
        !formData.shadowpayWssToken ||
        !formData.marketCsgoApiKey ||
        !formData.csgoEmpireApiKey ||
        !formData.CSGORollPassword ||
        !formData.CSGORollEmailId ||
        !formData.csgo500ApiKey ||
        !formData.csgo500UserId
      ) /*{
        setNotification({
          message:
            'All Non-Steam account details (waxpeerApiKey, shadowpayApiKey, shadowpayWssToken, marketCsgoApiKey, csgoEmpireApiKey, CSGORollPassword, CSGORollEmailId, csgo500ApiKey, csgo500UserId) are required.',
          type: 'error'
        });
        setLoading(false);
        return;
      }*/

      if (formData.proxy && !validateProxy(formData.proxy)) {
        setNotification({
          message: 'Proxy must be in the format http://login:pass@IP:port.',
          type: 'error'
        });
        setLoading(false);
        return;
      }
    }

    // Proceed with submission
    try {
      setNotification({
        message:
          'Account verification is in progress. This may take a few moments.',
        type: 'success'
      });

      const url = isEditMode
        ? `/api/account-details/${formData.nickname}`
        : '/api/account-details';
      const method = isEditMode ? 'PUT' : 'POST';

      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(updatedFormData)
      });

      const data = await response.json();

      if (response.ok) {
        if (data.verification && data.id) {
          setVerificationId(data.id);
          setVerificationPopupOpen(true);
          setNotification({
            message: data.message || 'Account added successfully.',
            type: 'success'
          });
        } else {
          onAddAccount(updatedFormData);
          setNotification({
            message: isEditMode ? 'Account updated successfully.' : 'Account added successfully.',
            type: 'success'
          });
          onClose();
        }
      } else {
        if (response.status === 500 && data.message === 'Verification is Not Completed.') {
          setVerificationId(data.id || verificationId);
          setVerificationError('Verification process is not complete. Please complete it and try again.');
          setVerificationPopupOpen(true);
        } else {
          setNotification({
            message: `Error: ${data.message}`,
            type: 'error'
          });
        }
      }
    } catch (error) {
      console.error('Error:', error);
      setNotification({
        message: 'An error occurred while processing the account.',
        type: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleVerification = async () => {
    if (!verificationId) {
      setNotification({
        message: 'Invalid verification ID.',
        type: 'error'
      });
      return;
    }

    setVerificationLoading(true);
    setVerificationError(null); // Reset any previous errors

    try {
      const response = await fetch('/api/verification', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ id: verificationId })
      });

      const data = await response.json();

      if (response.ok) {
        setNotification({
          message: data.message || 'Verification successful.',
          type: 'success'
        });
        setVerificationPopupOpen(false); // Close the popup
        onAddAccount(formData); // Update account list or state as needed
        onClose(); // Optionally close the AddAccountForm after verification
      } else {
        if (response.status === 500 && data.message === 'Verification is Not Completed.') {
          setVerificationError('Verification process is not complete. Please complete it and try again.');
        } else {
          setNotification({
            message: `Verification Error: ${data.message}`,
            type: 'error'
          });
        }
      }
    } catch (error) {
      console.error('Verification Error:', error);
      setNotification({
        message: 'An error occurred during verification.',
        type: 'error'
      });
    } finally {
      setVerificationLoading(false);
      // Note: Keep the popup open if there's an error to allow retry
    }
  };

  const handleDelete = async () => {
    try {
      const response = await fetch(
        `/api/account-details/${accountToEdit.nickname}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

      const data = await response.json();
      if (response.ok) {
        onDeleteAccount(accountToEdit.nickname);
        setNotification({
          message: 'Account deleted successfully.',
          type: 'success'
        });
        onClose();

        setTimeout(async () => {
          try {
            const refreshResponse = await fetch('/api/account-details', {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json'
              }
            });

            const refreshedAccounts = await refreshResponse.json();
            if (refreshResponse.ok) {
              onAddAccount(refreshedAccounts);
              setNotification({
                message: 'Account details refreshed successfully.',
                type: 'success'
              });
            } else {
              setNotification({
                message: `Error refreshing account details: ${refreshedAccounts.message}`,
                type: 'error'
              });
            }
          } catch (error) {
            console.error('Error refreshing account details:', error);
            setNotification({
              message: 'Error refreshing account details.',
              type: 'error'
            });
          }
        }, 2000);
      } else {
        setNotification({
          message: `Error: ${data.message}`,
          type: 'error'
        });
      }
    } catch (error) {
      console.error('Error:', error);
      setNotification({
        message: 'An error occurred while deleting the account.',
        type: 'error'
      });
    }
  };

  return (
    <div className="add-account-form-overlay">
      <div className="add-account-form">
        <h2>
          {isEditMode
            ? 'Edit Account'
            : accountType === 'steam'
            ? 'Add Steam Account'
            : 'Add Non-Steam Account'}
        </h2>

        {isEditMode && (
          <div className="tabs">
            <button
              className={`tab-button ${activeTab === 'steam' ? 'active' : ''}`}
              onClick={() => handleTabClick('steam')}
            >
              Edit Steam Details
            </button>
            <button
              className={`tab-button ${activeTab === 'apis' ? 'active' : ''}`}
              onClick={() => handleTabClick('apis')}
            >
              Edit APIs
            </button>
          </div>
        )}

        <form onSubmit={handleSubmit}>
          {isEditMode ? (
            <>
              {activeTab === 'steam' && (
                <SteamAccountForm
                  formData={formData}
                  setFormData={setFormData}
                  editableFields={editableFields}
                  toggleEditField={toggleEditField}
                  isEditMode={isEditMode}
                  setNotification={setNotification}
                  openCsgoEmpireConfirm={() => {
                    setCsgoEmpireConfirmOpen(true);
                    setPendingCsgoEmpireEnable(true);
                  }}
                  isCsgoEmpireEnabled={formData.registerCsgoEmpire}
                />
              )}
              {activeTab === 'apis' && (
                <NonSteamAccountForm
                  formData={formData}
                  setFormData={setFormData}
                  editableFields={editableFields}
                  toggleEditField={toggleEditField}
                  isEditMode={isEditMode}
                />
              )}
            </>
          ) : (
            accountType === 'steam' ? (
              <SteamAccountForm
                formData={formData}
                setFormData={setFormData}
                editableFields={editableFields}
                toggleEditField={toggleEditField}
                isEditMode={isEditMode}
                setNotification={setNotification}
                openCsgoEmpireConfirm={() => {
                  setCsgoEmpireConfirmOpen(true);
                  setPendingCsgoEmpireEnable(true);
                }}
                isCsgoEmpireEnabled={formData.registerCsgoEmpire}
              />
            ) : (
              <NonSteamAccountForm
                formData={formData}
                setFormData={setFormData}
                editableFields={editableFields}
                toggleEditField={toggleEditField}
                isEditMode={isEditMode}
              />
            )
          )}

          <div className="form-actions">
            <button type="submit" disabled={loading}>
              {loading ? (
                <InlineLoadingSpinner />
              ) : isEditMode ? (
                'Save Changes'
              ) : accountType === 'steam' ? (
                'Add Steam Account'
              ) : (
                'Add Non-Steam Account'
              )}
            </button>
            {isEditMode && (
              <button
                type="button"
                className="delete-btn"
                onClick={handleDelete}
                disabled={loading}
              >
                Delete Account
              </button>
            )}
            <button type="button" onClick={onClose} disabled={loading}>
              Cancel
            </button>
          </div>
        </form>
      </div>

      {/* Verification Popup */}
      <ModalPopup isOpen={verificationPopupOpen}>
        {!verificationError ? (
          <>
            <h3>Account Verification</h3>
            <p>Please verify your email for CSGOEmpire.</p>
            <button
              className="confirm-btn"
              onClick={handleVerification}
              disabled={verificationLoading}
            >
              {verificationLoading ? (
                <InlineLoadingSpinner />
              ) : (
                'I have verified the email for CSGOEMPIRE'
              )}
            </button>
          </>
        ) : (
          <>
            <h3>Verification Incomplete</h3>
            <p>{verificationError}</p>
            <button
              className="confirm-btn"
              onClick={handleVerification}
              disabled={verificationLoading}
            >
              {verificationLoading ? (
                <InlineLoadingSpinner />
              ) : (
                'Retry Verification'
              )}
            </button>
          </>
        )}
      </ModalPopup>

      {/* CSGOEmpire Confirmation Popup */}
      <ModalPopup isOpen={csgoEmpireConfirmOpen}>
        <h3>Confirm CSGOEmpire Activation</h3>
        <p>
          Are you sure you want to enable CSGOEmpire? Ensure you have not created an API
          on the provided email or linked it with any Steam account.
        </p>
        <div className="modal-actions">
          <button
            onClick={() => handleCsgoEmpireConfirmation(true)}
            className="confirm-btn"
          >
            Yes, Enable
          </button>
          <button
            onClick={() => handleCsgoEmpireConfirmation(false)}
            className="cancel-btn"
          >
            Cancel
          </button>
        </div>
      </ModalPopup>
    </div>
  );
}

export default AddAccountForm;
