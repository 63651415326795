// src/transactionsmodules/TransactionsFilters.jsx

import React, { useState, useEffect } from 'react';
import './TransactionsFilters.css'; // Existing CSS

function TransactionsFilters({ onFilter }) {
    const [nickname, setNickname] = useState('');
    const [operationType, setOperationType] = useState('');
    const [marketplace, setMarketplace] = useState('');
    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');
    const [availableNicknames, setAvailableNicknames] = useState([]);
    const [debouncedMarketplace, setDebouncedMarketplace] = useState('');

    // Fetch available nicknames for the dropdown
    useEffect(() => {
        const fetchNicknames = async () => {
            try {
                const response = await fetch('/api/users/account-nicknames', { credentials: 'include' });
                if (response.ok) {
                    const data = await response.json();
                    setAvailableNicknames(data.accountNicknames);
                } else {
                    console.error('Failed to fetch account nicknames');
                }
            } catch (error) {
                console.error('Error fetching account nicknames:', error);
            }
        };

        fetchNicknames();
    }, []);

    // Debounce the marketplace input
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedMarketplace(marketplace);
        }, 500); // 500ms debounce

        return () => {
            clearTimeout(handler);
        };
    }, [marketplace]);

    const handleFilter = (e) => {
        e.preventDefault();
        onFilter({ nickname, operationtype: operationType, marketplace: debouncedMarketplace, dateFrom, dateTo });
    };

    const handleReset = () => {
        setNickname('');
        setOperationType('');
        setMarketplace('');
        setDateFrom('');
        setDateTo('');
        onFilter({}); // Reset filters
    };

    return (
        <form className="transactions-filters" onSubmit={handleFilter}>
            <div className="filter-group">
                <label htmlFor="nickname">Nickname:</label>
                <select
                    id="nickname"
                    value={nickname}
                    onChange={(e) => setNickname(e.target.value)}
                >
                    <option value="">All</option>
                    {availableNicknames.map((nick) => (
                        <option key={nick} value={nick}>
                            {nick}
                        </option>
                    ))}
                </select>
            </div>

            <div className="filter-group">
                <label htmlFor="operationType">Operation Type:</label>
                <select
                    id="operationType"
                    value={operationType}
                    onChange={(e) => setOperationType(e.target.value)}
                >
                    <option value="">All</option>
                    <option value="Buy">Buy</option>
                    <option value="Sell">Sell</option>
                </select>
            </div>

            <div className="filter-group">
                <label htmlFor="marketplace">Marketplace:</label>
                <input
                    type="text"
                    id="marketplace"
                    value={marketplace}
                    onChange={(e) => setMarketplace(e.target.value)}
                    placeholder="Enter marketplace"
                />
            </div>

            <div className="filter-group">
                <label htmlFor="dateFrom">Date From:</label>
                <input
                    type="date"
                    id="dateFrom"
                    value={dateFrom}
                    onChange={(e) => setDateFrom(e.target.value)}
                />
            </div>

            <div className="filter-group">
                <label htmlFor="dateTo">Date To:</label>
                <input
                    type="date"
                    id="dateTo"
                    value={dateTo}
                    onChange={(e) => setDateTo(e.target.value)}
                />
            </div>

            <div className="filter-actions">
                <button type="submit">Apply Filters</button>
                <button type="button" onClick={handleReset} className="reset-button">
                    Reset Filters
                </button>
            </div>
        </form>
    );
}

export default TransactionsFilters;
