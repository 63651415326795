// src/components/InlineLoadingSpinner.js

import React from 'react';
import './InlineLoadingSpinner.css';

function InlineLoadingSpinner() {
  return <div className="inline-loading-spinner"></div>;
}

export default InlineLoadingSpinner;
