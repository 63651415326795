import React, { useState, useEffect } from 'react';
import './ProfitsBoxes.css'; // CSS specific to ProfitsBoxes
import axios from 'axios'; // Using axios for HTTP requests

function ProfitsBoxes({ selectedNickname }) { // Accept selectedNickname as a prop
    const defaultProfits = {
        today: { totalProfit: 0, averageProfitPercentage: 0 },
        last5Days: { totalProfit: 0, averageProfitPercentage: 0 },
        last10Days: { totalProfit: 0, averageProfitPercentage: 0 },
        last30Days: { totalProfit: 0, averageProfitPercentage: 0 }
    };
    const [profits, setProfits] = useState(defaultProfits);
    const [loading, setLoading] = useState(true);

    // Updated helper function to format period strings correctly
    function formatPeriod(period) {
        // Insert space between a lowercase letter and a number
        period = period.replace(/([a-z])([0-9]+)/g, '$1 $2');
        // Insert space between a number and an uppercase letter
        period = period.replace(/([0-9]+)([A-Z])/g, '$1 $2');
        // Insert space between a lowercase letter and an uppercase letter
        period = period.replace(/([a-z])([A-Z])/g, '$1 $2');
        // Capitalize the first letter of each word
        return period.replace(/\b\w/g, (match) => match.toUpperCase());
    }

    useEffect(() => {
        const fetchProfits = async () => {
            setLoading(true);
            try {
                let summaryResponse, averagesResponse;

                if (selectedNickname) {
                    summaryResponse = await axios.get(`/api/profits/summary/account/${encodeURIComponent(selectedNickname)}`, {
                        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
                    });

                    averagesResponse = await axios.get(`/api/profits/summary/account/${encodeURIComponent(selectedNickname)}/averages`, {
                        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
                    });
                } else {
                    summaryResponse = await axios.get('/api/profits/summary', {
                        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
                    });

                    averagesResponse = await axios.get('/api/profits/summary/averages', {
                        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
                    });
                }

                const { summary } = summaryResponse.data;
                const { averageSummary } = averagesResponse.data;

                if (selectedNickname) {
                    const accountProfits = summary[selectedNickname];
                    const accountAverages = averageSummary[selectedNickname];

                    setProfits({
                        today: {
                            totalProfit: accountProfits.today,
                            averageProfitPercentage: accountAverages.today
                        },
                        last5Days: {
                            totalProfit: accountProfits.last5Days,
                            averageProfitPercentage: accountAverages.last5Days
                        },
                        last10Days: {
                            totalProfit: accountProfits.last10Days,
                            averageProfitPercentage: accountAverages.last10Days
                        },
                        last30Days: {
                            totalProfit: accountProfits.last30Days,
                            averageProfitPercentage: accountAverages.last30Days
                        }
                    });
                } else {
                    let totalToday = 0, totalLast5Days = 0, totalLast10Days = 0, totalLast30Days = 0;
                    let avgToday = 0, avgLast5Days = 0, avgLast10Days = 0, avgLast30Days = 0;

                    Object.keys(summary).forEach(nickname => {
                        totalToday += summary[nickname].today;
                        totalLast5Days += summary[nickname].last5Days;
                        totalLast10Days += summary[nickname].last10Days;
                        totalLast30Days += summary[nickname].last30Days;
                    });

                    Object.keys(averageSummary).forEach(nickname => {
                        avgToday += averageSummary[nickname].today;
                        avgLast5Days += averageSummary[nickname].last5Days;
                        avgLast10Days += averageSummary[nickname].last10Days;
                        avgLast30Days += averageSummary[nickname].last30Days;
                    });

                    const accountCount = Object.keys(summary).length;

                    setProfits({
                        today: {
                            totalProfit: totalToday,
                            averageProfitPercentage: accountCount > 0 ? parseFloat((avgToday / accountCount).toFixed(2)) : 0
                        },
                        last5Days: {
                            totalProfit: totalLast5Days,
                            averageProfitPercentage: accountCount > 0 ? parseFloat((avgLast5Days / accountCount).toFixed(2)) : 0
                        },
                        last10Days: {
                            totalProfit: totalLast10Days,
                            averageProfitPercentage: accountCount > 0 ? parseFloat((avgLast10Days / accountCount).toFixed(2)) : 0
                        },
                        last30Days: {
                            totalProfit: totalLast30Days,
                            averageProfitPercentage: accountCount > 0 ? parseFloat((avgLast30Days / accountCount).toFixed(2)) : 0
                        }
                    });
                }
            } catch (err) {
                console.error('Error fetching profits:', err);
                setProfits(defaultProfits); // Set profits to default (0.00) on error
            } finally {
                setLoading(false);
            }
        };

        fetchProfits();
    }, [selectedNickname]); // Add selectedNickname as a dependency

    if (loading) {
        return <div className="profits-boxes">Loading...</div>;
    }

    return (
        <div className="profits-boxes">
            {/* Total Profits Boxes */}
            {['today', 'last5Days', 'last10Days', 'last30Days'].map((period) => (
                <div className="profits-box" key={period}>
                    <h3>{`${formatPeriod(period)} Profits`}</h3>
                    <p className="box-value">
                        ${profits[period].totalProfit.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </p>
                </div>
            ))}

            {/* Average Profit Percentage Boxes */}
            {['today', 'last5Days', 'last10Days', 'last30Days'].map((period) => (
                <div className="profits-box" key={`${period}-avg`}>
                    <h3>{`${formatPeriod(period)} Avg Profit %`}</h3>
                    <p className="box-average">{profits[period].averageProfitPercentage}%</p>
                </div>
            ))}
        </div>
    );
}

export default ProfitsBoxes;
