import React from "react";
import "./homepagefooter.css";
import logo from '../pictures/logo.png';  // Correct relative path
import discord from '../pictures/discord.png';
import twitter from '../pictures/twitter.png';
const Footer = () => {
  return (
    <footer id="footer">
      <div className="leftFooter">
        <h4>Get New Updates</h4>
        <div className="subscribe-container">
          <input type="email" placeholder="Enter your Email*" />
          <button>Subscribe</button>
        </div>
        <p>Stay informed with our latest news and exclusive content.</p>
      </div>

      <div className="midFooter">
        <img src={logo} alt="Master Panel Logo" className="footer-logo" /> {/* Display the logo */}
        <p>Just Relax And Let Us Take Care of Trading For You.</p>
        <p>© 2024 MasterPanel LLC. All Rights Reserved.</p>
        <h4>Follow Us</h4>
        <div className="social-icons">
          <a href="https://discord.gg/U7yaAJ2bSJ" target="_blank" rel="noopener noreferrer">
            <img src={discord} alt="Logo" className="logo" />
          </a>
          <a href="Will BE ADDED SOON" target="_blank" rel="noopener noreferrer">
            <img src={twitter} alt="Logo" className="logo" />
          </a>
        </div>
      </div>
      
      <div className="rightFooter">
        <h4>Contact Us</h4>
        <p>Email: support@masterspanel.com</p>

      </div>
    </footer>
  );
};

export default Footer;
