// App.js
import React, { useEffect, useCallback } from 'react';
import { Routes, Route } from 'react-router-dom'; // Removed useNavigate as it's unused
import HomePage from './HomePage';
import Dashboard from './Dashboard';
import Accounts from './Accounts';
import Buy from './Buy';
import Sell from './Sell';
import Profits from './Profits';
import Transactions from './Transactions'; // Import Transactions component

function App() {
  useEffect(() => {
    // Load Tawk.to script when the component mounts
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://embed.tawk.to/6720919e4304e3196ad9ea63/1ibbjhkvg';
    script.charset = 'UTF-8';
    script.setAttribute('crossorigin', '*');
    document.body.appendChild(script);

    // Clean up the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []); // Empty dependency array to ensure it only runs once

  // Function to fetch CSRF token and store it in localStorage
  const fetchCsrfToken = useCallback(async () => {
    try {
      const response = await fetch('/api/csrf-token', {
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch CSRF token: ${response.statusText}`);
      }

      const data = await response.json();

      if (process.env.NODE_ENV === 'development') {
        //console.log('CSRF token fetched:', data.csrfToken);
      }

      if (data.csrfToken) {
        localStorage.setItem('csrfToken', data.csrfToken);
      } else {
        throw new Error('CSRF token not found in the response');
      }
    } catch (error) {
      console.error('Error fetching CSRF token:', error);
    }
  }, []);

  // Fetch CSRF token on component mount
  useEffect(() => {
    fetchCsrfToken();
  }, [fetchCsrfToken]);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/accounts" element={<Accounts />} />
        <Route path="/buy" element={<Buy />} />
        <Route path="/sell" element={<Sell />} />
        <Route path="/profits" element={<Profits />} />
        <Route path="/transactions" element={<Transactions />} /> {/* New Route */}
      </Routes>
    </div>
  );
}

export default App;
