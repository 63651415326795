
import React from 'react';

const PrivacyPolicy = () => (
  <div>
    <h3>Privacy Policy</h3>
    <p><strong>Last Updated:</strong> 01/12/2024</p>

    <h4>1. Introduction</h4>
    <p>
      MasterPanel LLC ("MasterPanel," "we," "our," or "us") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our Service. Please read this policy carefully to understand our practices regarding your personal data.
    </p>

    <h4>2. Information We Collect</h4>
    <p><strong>Personal Information:</strong> When you create an account, we may collect personal information such as your name, email address, postal address, phone number, and payment information.</p>
    <p><strong>Usage Data:</strong> We collect information about how you access and use the Service, including your IP address, browser type, operating system, access times, and pages viewed.</p>
    <p><strong>Cookies and Tracking Technologies:</strong> We use cookies and similar tracking technologies to track activity on our Service and hold certain information.</p>

    <h4>3. How We Use Your Information</h4>
    <p>We use the collected information for various purposes, including to:</p>
    <ul>
      <li>Provide and maintain our Service</li>
      <li>Process transactions and manage payments</li>
      <li>Communicate with you, including sending updates and support</li>
      <li>Improve our Service through analysis and feedback</li>
      <li>Ensure the security and integrity of our Service</li>
    </ul>

    <h4>4. Sharing Your Information</h4>
    <p>We may share your information in the following circumstances:</p>
    <ul>
      <li><strong>Service Providers:</strong> We may employ third-party companies to facilitate our Service, provide the Service on our behalf, perform Service-related services, or assist us in analyzing how our Service is used.</li>
      <li><strong>Legal Requirements:</strong> We may disclose your information if required to do so by law or in response to valid requests by public authorities.</li>
      <li><strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred.</li>
    </ul>
    <p>We do not sell, trade, or otherwise transfer your personal information to outside parties except as described above.</p>

    <h4>5. Data Security</h4>
    <p>
      We implement a variety of security measures to maintain the safety of your personal information. However, no method of transmission over the Internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
    </p>

    <h4>6. Your Rights</h4>
    <p>Depending on your location, you may have the following rights regarding your personal data:</p>
    <ul>
      <li><strong>Access:</strong> Request access to your personal information.</li>
      <li><strong>Correction:</strong> Request correction of inaccurate or incomplete information.</li>
      <li><strong>Deletion:</strong> Request deletion of your personal information.</li>
      <li><strong>Objection:</strong> Object to the processing of your personal information.</li>
      <li><strong>Portability:</strong> Request the transfer of your personal information to another party.</li>
    </ul>
    <p>
      To exercise these rights, please contact us using the information provided below.
    </p>

    <h4>7. Children's Privacy</h4>
    <p>
      Our Service is not intended for individuals under the age of 18. We do not knowingly collect personal information from children under 18. If you become aware that a child has provided us with personal information, please contact us, and we will take steps to remove such information.
    </p>

    <h4>8. Changes to This Privacy Policy</h4>
    <p>
      We may update our Privacy Policy from time to time. We will notify you of any changes by updating the "Last Updated" date. We encourage you to review this Privacy Policy periodically for any changes.
    </p>

    <h4>9. Governing Law</h4>
    <p>
      This Privacy Policy shall be governed by and construed in accordance with the laws of the Commonwealth of Kentucky, without regard to its conflict of law principles.
    </p>

    <h4>10. Contact Us</h4>
    <p>
      If you have any questions about this Privacy Policy, please contact us at:
    </p>
    <p>
      MasterPanel LLC<br />
      6844 Bardstown Rd #5052, Louisville, KY 40291
      40291
      Miami
      United States<br />
      support@masterspanel.com<br />
    </p>
  </div>
);

export default PrivacyPolicy;
