// src/Homepagemodules/Content.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Content.css';

function Content({ authenticated, setShowForm }) {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    if (!authenticated) {
      setShowForm(true);  // Show the signup form if authenticated
    } else {
      alert('Please login or signup to continue!');
    }
  };

  return (
    <div className="content">
      <div className="text-content">
        <h1>A Complete P2P Solution For Your Trading Business</h1>
        <p>
          Unlock unparalleled control over your CS 2 trading empire. Seamlessly manage all your Steam accounts with cutting-edge analytics and lightning-fast transactions—all from one powerful platform.
        </p>
        <div className="cta-container">
          <button className="cta-button" onClick={handleButtonClick}>
            {authenticated ? 'Panel Awaits Its Master' : 'Login or Signup for Greatness'}
          </button>

          {/* Trustpilot Widget */}
          <div id="trustbox" className="trustpilot-widget" data-locale="en-US" data-template-id="56278e9abfbbba0bdcd568bc" data-businessunit-id="671d3bdbfec4d344e6de7b7d" data-style-height="50px" data-style-width="100%">
          <a href="https://www.trustpilot.com/review/masterspanel.com" target="_blank" rel="noopener">Trustpilot</a>
        </div>
        </div>
        <ul className="results">
          <li>Seamlessly Sell Across All P2P Platforms with Full Customization</li>
          <li>Streamlined Buying Experience on Every Supported Platform</li>
          <li>Comprehensive Analytics for In-Depth Trading Insights</li>
        </ul>
      </div>
    </div>
  );
}

export default Content;
