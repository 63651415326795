// src/transactionsmodules/TransactionsTable.jsx

import React from 'react';
import TransactionsFilters from './TransactionsFilters';
import './TransactionsTable.css';

function TransactionsTable({ transactions, onFilterApplied }) {
    return (
        <div className="transactions-table-container">
            {/* Filters */}
            <TransactionsFilters onFilter={onFilterApplied} />

            {/* Transactions Table */}
            <table className="transactions-table">
                <thead>
                    <tr>
                        {/* Removed the ID column */}
                        <th>Nickname</th>
                        <th>Item Name</th>
                        <th>Marketplace</th>
                        <th>Account Type</th>
                        <th>Deduction</th>
                        <th>Date</th>
                        <th>Operation Type</th>
                        <th>Price</th>
                    </tr>
                </thead>
                <tbody>
                    {transactions.length > 0 ? (
                        transactions.map((txn) => (
                            <tr key={txn.id}>
                                <td>{txn.nickname}</td>
                                <td>{txn.ItemName}</td>
                                <td>{txn.marketplace}</td>
                                <td>{txn.accounttype}</td>
                                <td>{txn.deduction}</td>
                                <td>{new Date(txn.date).toLocaleString()}</td>
                                <td>{txn.operationtype}</td>
                                <td>{txn.price}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="8">No transactions found.</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}

export default TransactionsTable;
