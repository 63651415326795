// src/Homepagemodules/ProblemSolution.js
import React from 'react';
import './ProblemSolution.css';

function ProblemSolution() {
  return (
    <div className="problem-solution">
      <h2 className="problem-heading">Too Many Accounts to Handle?</h2>
      <p className="problem-description">
        <strong>
          We know how frustrating it can be to handle multiple Steam accounts—or even a single one—constantly buying and selling. All the data and analytics not being shown live, and we find ourselves falling behind the market and competition.
        </strong>
      </p>
      <p className="problem-details">
        We've all been in a situation where we don't even know what item we bought for what price or what item sold where at what price. What was the profit on that specific item? And when we scale this up to multiple accounts, it becomes a nightmare.
      </p>
      <p className="solution-details">
        This is where the Master Panel comes in, with fully automatic buying and selling processes, handling all the data with pinpoint accuracy. You get individual account data as well as cumulative data, all updated live.
      </p>
    </div>
  );
}

export default ProblemSolution;
