import React, { useState, useEffect } from 'react';
import './SettingsPanel.css';

function SettingsPanel({ 
  boxVisibility, 
  handleVisibilityChange, 
  saveVisibilityPreferences, 
  settingsRef, 
  showSettings, 
  handleModifyValue, 
  modifications, 
  modifierType // Accept modifierType as a prop
}) {
  const [activePage, setActivePage] = useState('visibility');
  const [modifiedValues, setModifiedValues] = useState({ ...modifications });

  const switchToModifiersPage = () => setActivePage('modifiers');
  const switchToVisibilityPage = () => setActivePage('visibility');

  // Fetch modifiers when the component mounts or when modifierType or activePage changes
  useEffect(() => {
    if (activePage === 'modifiers') {
      fetchModifications();
    }
  }, [modifierType, activePage]);

  // Fetch account/dashboard modifiers based on modifierType
  const fetchModifications = async () => {
    try {
      const response = await fetch('/api/mainbalancemodifier', { credentials: 'include' });
      if (response.ok) {
        const data = await response.json();
        const modifiers =
          modifierType === 'accounts' ? data.accountsModifiers : data.dashboardModifiers;

        // Update state with the fetched modifiers
        setModifiedValues(modifiers);
        handleModifyValue(modifierType, modifiers); // Update state in the parent component
      } else {
        console.error('Failed to fetch balance modifiers');
      }
    } catch (error) {
      console.error('Error fetching balance modifiers:', error);
    }
  };

  const handleInputChange = (field, value) => {
    setModifiedValues(prev => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSaveChanges = async () => {
    try {
      const response = await fetch('/api/mainbalancemodifier', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          modifierType,
          modifiers: modifiedValues,
        }),
      });

      if (response.ok) {
        handleModifyValue(modifierType, modifiedValues); // Update state in the parent component
      } else {
        console.error('Failed to update modifiers');
      }
    } catch (error) {
      console.error('Error updating modifiers:', error);
    }
  };

  return (
    <>
      {showSettings && <div className="overlay" />}
      <div className={`settings-panel ${showSettings ? 'visible' : ''}`} ref={settingsRef}>
        <h2>Settings</h2>

        <div className="settings-page-switch">
          <button onClick={switchToVisibilityPage} className={activePage === 'visibility' ? 'active' : ''}>
            Visibility
          </button>
          <button onClick={switchToModifiersPage} className={activePage === 'modifiers' ? 'active' : ''}>
            Balance Modifiers
          </button>
        </div>

        {activePage === 'visibility' && (
          <>
            <h3>Box Visibility Settings</h3>
            {Object.keys(boxVisibility).map(boxName => (
              <div key={boxName} className="settings-item">
                <label>
                  <input
                    type="checkbox"
                    checked={!!boxVisibility[boxName]}
                    onChange={() => handleVisibilityChange(boxName)}
                  />
                  {boxName.charAt(0).toUpperCase() + boxName.slice(1)}
                </label>
              </div>
            ))}
            <button className="save-button" onClick={saveVisibilityPreferences}>Save Visibility</button>
          </>
        )}

        {activePage === 'modifiers' && (
          <>
            <h3>Balance Modifiers</h3>
            <div className="modifiers-section">
              {boxVisibility.MarketCSGOBalance && (
                <div className="modifier-item">
                  <label htmlFor="marketFeePercent">Market.CSGO Balance Modifier</label>
                  <input
                    id="marketFeePercent"
                    type="number"
                    value={modifiedValues.marketFeePercent ?? 100}
                    onChange={e => handleInputChange('marketFeePercent', e.target.value)}
                    className="modifier-input"
                  />
                </div>
              )}
              {boxVisibility.WaxpeerBalance && (
                <div className="modifier-item">
                  <label htmlFor="waxpeerFeePercent">Waxpeer Balance Modifier</label>
                  <input
                    id="waxpeerFeePercent"
                    type="number"
                    value={modifiedValues.waxpeerFeePercent ?? 100}
                    onChange={e => handleInputChange('waxpeerFeePercent', e.target.value)}
                    className="modifier-input"
                  />
                </div>
              )}
              {boxVisibility.ShadowPayBalance && (
                <div className="modifier-item">
                  <label htmlFor="shadowPayFeePercent">ShadowPay Balance Modifier</label>
                  <input
                    id="shadowPayFeePercent"
                    type="number"
                    value={modifiedValues.shadowPayFeePercent ?? 100}
                    onChange={e => handleInputChange('shadowPayFeePercent', e.target.value)}
                    className="modifier-input"
                  />
                </div>
              )}
              {boxVisibility.CsgoEmpireBalance && (
                <div className="modifier-item">
                  <label htmlFor="csgoEmpireFeePercent">CSGOEmpire Balance Modifier</label>
                  <input
                    id="csgoEmpireFeePercent"
                    type="number"
                    value={modifiedValues.csgoEmpireFeePercent ?? 100}
                    onChange={e => handleInputChange('csgoEmpireFeePercent', e.target.value)}
                    className="modifier-input"
                  />
                </div>
              )}
              {boxVisibility.CSGORollBalance && (
                <div className="modifier-item">
                  <label htmlFor="CSGORollFeePercent">CSGORoll Balance Modifier</label>
                  <input
                    id="CSGORollFeePercent"
                    type="number"
                    value={modifiedValues.CSGORollFeePercent ?? 100}
                    onChange={e => handleInputChange('CSGORollFeePercent', e.target.value)}
                    className="modifier-input"
                  />
                </div>
              )}
              {boxVisibility.Csgo500Balance && (
                <div className="modifier-item">
                  <label htmlFor="csgo500FeePercent">CSGO500 Balance Modifier</label>
                  <input
                    id="csgo500FeePercent"
                    type="number"
                    value={modifiedValues.csgo500FeePercent ?? 100}
                    onChange={e => handleInputChange('csgo500FeePercent', e.target.value)}
                    className="modifier-input"
                  />
                </div>
              )}
              {boxVisibility.steamInventoryWaxpeer && (
                <div className="modifier-item">
                  <label htmlFor="steamInventoryWaxpeer">Steam Inventory (Waxpeer)</label>
                  <input
                    id="steamInventoryWaxpeer"
                    type="number"
                    value={modifiedValues.steamInventoryWaxpeer ?? 100}
                    onChange={e => handleInputChange('steamInventoryWaxpeer', e.target.value)}
                    className="modifier-input"
                  />
                </div>
              )}
              {boxVisibility.steamInventoryBuff && (
                <div className="modifier-item">
                  <label htmlFor="steamInventoryBuff">Steam Inventory (Buff163)</label>
                  <input
                    id="steamInventoryBuff"
                    type="number"
                    value={modifiedValues.steamInventoryBuff ?? 100}
                    onChange={e => handleInputChange('steamInventoryBuff', e.target.value)}
                    className="modifier-input"
                  />
                </div>
              )}
            </div>
            <button className="save-button" onClick={handleSaveChanges}>Save Modifiers</button>
          </>
        )}
      </div>
    </>
  );
}

export default SettingsPanel;
