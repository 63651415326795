// src/components/SignupLoginSetup.jsx

import React, { useState, useEffect, useRef, useCallback } from 'react';
import './SignupLoginSetup.css';
import { useNavigate } from 'react-router-dom';
import PolicyModal from './PolicyModal';

// Import the separate policy components
import TermsOfService from './TermsOfService';
import PrivacyPolicy from './PrivacyPolicy';
import RefundPolicy from './RefundPolicy';

const SignupLoginSetup = ({ closeForm }) => {
  const [activeTab, setActiveTab] = useState('signup');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [usernameOrEmail, setUsernameOrEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [fade, setFade] = useState(false);
  const [agreed, setAgreed] = useState(false);

  // State for managing which policy modal is open
  const [modalOpen, setModalOpen] = useState(false);
  const [currentPolicy, setCurrentPolicy] = useState('');

  const modalRef = useRef(null);
  const navigate = useNavigate();

  const handleTabClick = (tab) => {
    if (tab !== activeTab) {
      setActiveTab(tab);
      setError('');
      setSuccess('');
    }
  };

  const handleClickOutside = useCallback((event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      closeForm();
    }
  }, [closeForm]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    if (success || error) {
      setFade(false);
      const timer = setTimeout(() => {
        setFade(true);
        setTimeout(() => {
          setSuccess('');
          setError('');
        }, 500);
      }, 4500);

      return () => clearTimeout(timer);
    }
  }, [success, error]);

  const handleSignUp = async (event) => {
    event.preventDefault();

    if (!agreed) {
      setError('You must agree to the Terms of Service, Privacy Policy, and Refund Policy.');
      return;
    }

    if (password.length < 7) {
      setError('Password must be at least 7 characters long.');
      return;
    }

    try {
      const response = await fetch('/api/signup', { // Ensure the endpoint is correct
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, email, password }),
      });

      const data = await response.json();

      if (response.ok) {
        setSuccess('Sign-up successful!');
        setUsername('');
        setEmail('');
        setPassword('');
        setAgreed(false);
      } else {
        setError(data.message || 'Sign-up failed!');
      }
    } catch (err) {
      setError('An error occurred during sign-up.');
    }
  };

  const handleLogin = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch('/api/login', { // Ensure the endpoint is correct
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ usernameOrEmail, password }),
      });

      const data = await response.json();

      if (response.ok) {
        setSuccess('Login successful!');
        setUsernameOrEmail('');
        setPassword('');
        navigate('/dashboard');
      } else {
        setError(data.message || 'Login failed!');
      }
    } catch (err) {
      setError('An error occurred during login.');
    }
  };

  // Function to open the policy modal
  const openPolicyModal = (policy) => {
    setCurrentPolicy(policy);
    setModalOpen(true);
  };

  // Function to close the policy modal
  const closePolicyModal = () => {
    setModalOpen(false);
    setCurrentPolicy('');
  };

  // Function to render the appropriate policy component
  const renderPolicyContent = () => {
    switch (currentPolicy) {
      case 'terms':
        return <TermsOfService />;
      case 'privacy':
        return <PrivacyPolicy />;
      case 'refund':
        return <RefundPolicy />;
      default:
        return null;
    }
  };

  return (
    <div className="signup-login-modal">
      <div className="modal-content" ref={modalRef}>
        <button className="close-button" onClick={closeForm}>X</button>
        <div className="tab-buttons">
          <button 
            className={`tab ${activeTab === 'login' ? 'active' : ''}`} 
            onClick={() => handleTabClick('login')}
          >
            Login
          </button>
          <button 
            className={`tab ${activeTab === 'signup' ? 'active' : ''}`} 
            onClick={() => handleTabClick('signup')}
          >
            Sign Up
          </button>
        </div>
        <div className={`tab-content ${activeTab === 'signup' ? 'slide-in-left' : 'slide-in-right'}`}>
          {activeTab === 'signup' && (
            <div className="signup-form">
              <h2>Sign Up</h2>
              <form onSubmit={handleSignUp}>
                <div>
                  <label>Username:</label>
                  <input 
                    type="text" 
                    value={username} 
                    onChange={(e) => setUsername(e.target.value)} 
                    required 
                  />
                </div>
                <div>
                  <label>Email:</label>
                  <input 
                    type="email" 
                    value={email} 
                    onChange={(e) => setEmail(e.target.value)} 
                    required 
                  />
                </div>
                <div>
                  <label>Password:</label>
                  <input 
                    type="password" 
                    value={password} 
                    onChange={(e) => setPassword(e.target.value)} 
                    required 
                  />
                </div>
                
                {/* Checkbox for agreeing to policies */}
                <div className="agreement">
                  <input 
                    type="checkbox" 
                    id="agree" 
                    checked={agreed} 
                    onChange={(e) => setAgreed(e.target.checked)} 
                  />
                  <label htmlFor="agree">
                    I agree to the{' '}
                    <span className="policy-link" onClick={() => openPolicyModal('terms')}>
                      Terms of Service
                    </span>,{' '}
                    <span className="policy-link" onClick={() => openPolicyModal('privacy')}>
                      Privacy Policy
                    </span>, and{' '}
                    <span className="policy-link" onClick={() => openPolicyModal('refund')}>
                      Refund Policy
                    </span>.
                  </label>
                </div>

                <button type="submit" className="submit-button" disabled={!agreed}>
                  Sign Up
                </button>
              </form>
              {error && <div className={`message-box error-message ${fade ? 'hidden' : ''}`}>{error}</div>}
              {success && <div className={`message-box success-message ${fade ? 'hidden' : ''}`}>{success}</div>}
            </div>
          )}
          {activeTab === 'login' && (
            <div className="login-form">
              <h2>Login</h2>
              <form onSubmit={handleLogin}>
                <div>
                  <label>Username or Email:</label>
                  <input 
                    type="text" 
                    value={usernameOrEmail} 
                    onChange={(e) => setUsernameOrEmail(e.target.value)} 
                    required 
                  />
                </div>
                <div>
                  <label>Password:</label>
                  <input 
                    type="password" 
                    value={password} 
                    onChange={(e) => setPassword(e.target.value)} 
                    required 
                  />
                </div>
                <button type="submit" className="submit-button">Login</button>
              </form>
              {error && <div className={`message-box error-message ${fade ? 'hidden' : ''}`}>{error}</div>}
              {success && <div className={`message-box success-message ${fade ? 'hidden' : ''}`}>{success}</div>}
            </div>
          )}
        </div>
      </div>

      {/* Policy Modal */}
      <PolicyModal 
        isOpen={modalOpen} 
        onClose={closePolicyModal} 
        title={
          currentPolicy === 'terms' 
            ? 'Terms of Service' 
            : currentPolicy === 'privacy' 
            ? 'Privacy Policy' 
            : currentPolicy === 'refund' 
            ? 'Refund Policy' 
            : ''
        }
      >
        {renderPolicyContent()}
      </PolicyModal>
    </div>
  );
};

export default SignupLoginSetup;
