// src/components/NotificationSlider.js

import React, { useState, useEffect } from 'react';
import './NotificationSlider.css';

const NotificationSlider = ({ message, type, duration = 3000 }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (message) {
      setVisible(true);
      const timer = setTimeout(() => {
        setVisible(false);
      }, duration);

      return () => clearTimeout(timer);
    }
  }, [message, duration]);

  return (
    <div className={`notification-slider ${visible ? 'visible' : ''} ${type}`}>
      {message}
    </div>
  );
};

export default NotificationSlider;
