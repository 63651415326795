// Profits.js
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from './Homepagemodules/Navbar';
import Footer from './Homepagemodules/homepagefooter';
import ProfitsOverview from './profitmodules/ProfitsOverview';
import ProfitsBoxes from './profitmodules/ProfitsBoxes';
import AccountSearch from './profitmodules/AccountSearch';
import ProfitsTable from './profitmodules/ProfitsTable'; // Import ProfitsTable
import './Profits.css'; 
import './components/loadingscreen.css';
function Profits() {
    const [loading, setLoading] = useState(true);
    const [authenticated, setAuthenticated] = useState(false);
    const navigate = useNavigate();
    const hasFetched = useRef(false);
    const [selectedNickname, setSelectedNickname] = useState(null); // State for selected nickname

    useEffect(() => {
        const checkAuth = async () => {
            try {
                if (hasFetched.current) return;
                hasFetched.current = true;

                const response = await fetch('/api/auth/isAuthenticated', { credentials: 'include' });
                if (response.ok) {
                    setAuthenticated(true);
                } else {
                    navigate('/');
                }
            } catch (error) {
                console.error('Error checking authentication:', error);
                navigate('/');
            } finally {
                setLoading(false);
            }
        };
        checkAuth();
    }, [navigate]);

    if (loading) {
        return (
          <div className="loading-screen">
            <div className="loading-spinner"></div>
          </div>
        );
      }

    if (!authenticated) {
        return null;
    }

    return (
        <div className="profits-page">
            {/* Navbar */}
            <Navbar authenticated={authenticated} setAuthenticated={setAuthenticated} />

            {/* Profits content container */}
            <div className="profits-content">
                <div className="profits-overview-header">
                    <ProfitsOverview /> {/* Profits Overview Heading */}
                    <AccountSearch 
                        selectedNickname={selectedNickname} 
                        setSelectedNickname={setSelectedNickname} 
                    /> {/* Pass state and setter */}
                </div>
                <ProfitsBoxes selectedNickname={selectedNickname} /> {/* Pass selectedNickname */}
                <ProfitsTable selectedNickname={selectedNickname} /> {/* Pass selectedNickname */}
            </div>

            {/* Footer */}
            <Footer />
        </div>
    );
}

export default Profits;
