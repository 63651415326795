// src/Homepagemodules/FAQ.js
import React, { useEffect } from 'react';
import './FAQ.css';

function FAQ() {
  useEffect(() => {
    const faqContainer = document.querySelector('.faq-section');

    const toggleAnswer = (event) => {
      if (event.target.classList.contains('faq-question')) {
        const button = event.target;
        const faqAnswer = button.nextElementSibling;

        button.classList.toggle('active');

        if (button.classList.contains('active')) {
          faqAnswer.style.maxHeight = `${faqAnswer.scrollHeight}px`;
        } else {
          faqAnswer.style.maxHeight = '0';
        }
      }
    };

    faqContainer.addEventListener('click', toggleAnswer);

    // Cleanup function to remove event listener
    return () => {
      faqContainer.removeEventListener('click', toggleAnswer);
    };
  }, []);

  return (
    <div className="faq-section">
      <h2 className="faq-heading">Still Not Satisfied?</h2>
      <div className="faq-item">
        <button className="faq-question">Q: How do I get started?</button>
        <div className="faq-answer">
          <p>To get started you only need the API keys of your markets that you want to trade on. No Steam credentials required. However, this will not enable Auto Delivery or Auto Acceptance of bought items.</p>
        </div>
      </div>
      <div className="faq-item">
        <button className="faq-question">Q: How will you enable Auto Delivery of items?</button>
        <div className="faq-answer">
          <p>We use accounts that are already set up on SDA (Steam Desktop Authenticator), which gives us access to the mafile of that account, hence automating everything.</p>
        </div>
      </div>
      <div className="faq-item">
        <button className="faq-question">Q: Will my MAFILE and account credentials be safe?</button>
        <div className="faq-answer">
          <p>We store even the smallest details, like emails, with high-level encryption at every stage, ensuring the system is very secure.</p>
        </div>
      </div>
      <div className="faq-item">
        <button className="faq-question">Q: What if I don't have my account set up on the SDA (Steam Desktop Authenticator)?</button>
        <div className="faq-answer">
          <p>Don't worry. Even if you haven't set up your account on the SDA and don't want to, you can still use our buying algorithms and price optimizations for your skins.</p>
        </div>
      </div>
      <div className="faq-item">
        <button className="faq-question">Q: Are you guys trustworthy?</button>
        <div className="faq-answer">
          <p>At MasterPanel LLC, trust isn’t just a promise – it’s the foundation of everything we do. Our commitment goes beyond transactions; we’re here to build lasting relationships grounded in transparency, reliability, and excellence. Your satisfaction is not just our goal; it’s our mission. As your trusted partner, we will go the extra mile, ensuring that every interaction reflects our dedication to your success. We are in this for the long haul – because at MasterPanel, your trust is our greatest achievement.</p>
        </div>
      </div>
      <div className="faq-item">
        <button className="faq-question">Q: What if something bad happens?</button>
        <div className="faq-answer">
          <p>We will soon start building a SAFU fund for the security and inventory protection of our customers. However, this will not cover Steam bans.</p>
        </div>
      </div>
    </div>
  );
}

export default FAQ;
