// profitmodules/ProfitsOverview.js
import React from 'react';
import './ProfitsOverview.css'; // CSS specific to ProfitsOverview

function ProfitsOverview() {
    return (
        <div className="profits-overview">
            <h2 className="profits-heading">Profits Overview</h2>
        </div>
    );
}

export default ProfitsOverview;
