// Inside AccountSearch.js
import React, { useState, useEffect, useRef } from 'react';
import { FaCog, FaTimes } from 'react-icons/fa';
import './AccountSearch.css';

function AccountSearch({ selectedNickname, setSelectedNickname }) {
    const [searchInput, setSearchInput] = useState('');
    const [filteredAccounts, setFilteredAccounts] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [accountNicknames, setAccountNicknames] = useState([]);
    const [settingsDropdownVisible, setSettingsDropdownVisible] = useState(false);
    const searchRef = useRef(null);
    const debounceTimeout = useRef(null); // Ref for debounce

    useEffect(() => {
        // Fetch account nicknames when component mounts
        const fetchAccountNicknames = async () => {
            try {
                const response = await fetch('/api/users/account-nicknames', { credentials: 'include' });
                if (response.ok) {
                    const data = await response.json();
                    setAccountNicknames(data.accountNicknames);
                    setFilteredAccounts(data.accountNicknames);
                } else {
                    console.error('Failed to fetch account nicknames');
                }
            } catch (error) {
                console.error('Error fetching account nicknames:', error);
            }
        };
        fetchAccountNicknames();
    }, []);

    const handleSearchChange = (e) => {
        const query = e.target.value;
        setSearchInput(query);
        if (!showDropdown) setShowDropdown(true);

        // Clear previous debounce timeout
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        // Set new debounce timeout
        debounceTimeout.current = setTimeout(() => {
            if (query.trim() === '') {
                setFilteredAccounts(accountNicknames);
            } else {
                const filtered = accountNicknames.filter((nickname) =>
                    nickname.toLowerCase().includes(query.toLowerCase())
                );
                setFilteredAccounts(filtered);
            }
        }, 300); // 300ms debounce delay
    };

    const handleAccountSelect = (nickname) => {
        setSearchInput(nickname);
        setShowDropdown(false);
        setSelectedNickname(nickname); // Set the selected nickname
    };

    const handleSettingsClick = () => {
        setSettingsDropdownVisible((prev) => !prev);
    };

    const handleClearSelection = () => {
        setSelectedNickname(null); // Clear the selected nickname
        setSearchInput('');
        setFilteredAccounts(accountNicknames);
    };

    // Handle click outside to close dropdown and settings
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchRef.current && !searchRef.current.contains(event.target)) {
                setShowDropdown(false);
                setSettingsDropdownVisible(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="profits-search-and-account" ref={searchRef}>
            {/* Clear Selection Button */}
            {selectedNickname && (
                <button 
                    className="profits-clear-selection-button" 
                    onClick={handleClearSelection}
                    aria-label="Clear Account Selection"
                >
                    <FaTimes />
                </button>
            )}

            {/* Search Bar */}
            <input
                type="text"
                className="profits-search-box"
                placeholder="Search Accounts..."
                value={searchInput}
                onChange={handleSearchChange}
                onFocus={() => setShowDropdown(true)}
                aria-label="Search Accounts"
            />
            {/* Settings Cog */}
            <FaCog
                className="profits-settings-cog"
                aria-label="Settings"
                onClick={handleSettingsClick}
                tabIndex="0"
                role="button"
                onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        handleSettingsClick();
                    }
                }}
            />

            {/* Settings Dropdown */}
            {settingsDropdownVisible && (
                <div className="profits-settings-dropdown">
                    {/* Add settings options here */}
                    <p>Settings Content Here</p>
                </div>
            )}

            {/* Account Search Dropdown */}
            {showDropdown && (
                <div className="profits-account-search-dropdown">
                    {filteredAccounts.length > 0 ? (
                        filteredAccounts.map((nickname, index) => (
                            <div
                                key={index}
                                className="profits-search-item"
                                onClick={() => handleAccountSelect(nickname)}
                                role="option"
                                tabIndex="0"
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        handleAccountSelect(nickname);
                                    }
                                }}
                            >
                                {nickname}
                            </div>
                        ))
                    ) : (
                        <div className="profits-search-item no-accounts">No Accounts Found</div>
                    )}
                </div>
            )}
        </div>
    );
}

export default AccountSearch;
