// Accounts.js
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaCog } from 'react-icons/fa'; // Import FaCog directly
import Navbar from './Homepagemodules/Navbar';
import Footer from './Homepagemodules/homepagefooter';
import DashboardBoxes from './dashboardmodules/DashboardBoxes';
import AccountsTable from './accountsmodules/AccountsTable';
// Removed: import DashboardHeader from './dashboardmodules/DashboardHeader';
import SettingsPanel from './dashboardmodules/SettingsPanel';
import './Accounts.css'; // Ensure this is the only CSS file imported
import NotificationSlider from './components/NotificationSlider';
import './components/loadingscreen.css';
function Accounts() {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [accountsData, setAccountsData] = useState([]);
  const [accountModifiers, setAccountModifiers] = useState({});
  const [selectedAccountName, setSelectedAccountName] = useState("No Account Selected");
  const [showSettings, setShowSettings] = useState(false);
  const [notification, setNotification] = useState({ message: '', type: '' });
  const [calculatedValues, setCalculatedValues] = useState({
    waxpeer: 0,
    buff163: 0,
    WaxpeerBalance: 0,
    MarketCSGOBalance: 0,
    ShadowPayBalance: 0,
    CsgoEmpireBalance: 0,
    CSGORollBalance: 0,
    Csgo500Balance: 0,
    grandTotal: 0,
    steamInventoryWaxpeer: 0,
    steamInventoryBuff: 0,
  });
  const [boxVisibility, setBoxVisibility] = useState({
    waxpeer: true,
    buff163: true,
    MarketCSGOBalance: true,
    WaxpeerBalance: true,
    ShadowPayBalance: true,
    CsgoEmpireBalance: true,
    CSGORollBalance: true,
    Csgo500Balance: true,
    grandTotal: true,
    steamInventoryWaxpeer: true,
    steamInventoryBuff: true
  });
  const settingsRef = useRef(null);
  const hasFetched = useRef(false);
  const navigate = useNavigate();

  const toggleSettingsPanel = () => {
    setShowSettings(prev => !prev);
  };
  const handleVisibilityChange = (boxName) => {
    setBoxVisibility(prev => ({
      ...prev,
      [boxName]: !prev[boxName]
    }));
  };
  useEffect(() => {
    const fetchVisibilityPrefs = async () => {
      try {
        const response = await fetch('/api/visibility-prefs', { credentials: 'include' });
        if (response.ok) {
          const data = await response.json();
          const mappedData = {
            waxpeer: data.waxpeer,
            buff163: data.buff163,
            MarketCSGOBalance: data.MarketCSGOBalance,
            ShadowPayBalance: data.ShadowPayBalance,
            WaxpeerBalance: data.WaxpeerBalance,
            CsgoEmpireBalance: data.CsgoEmpireBalance,
            CSGORollBalance: data.CSGORollBalance,
            Csgo500Balance: data.Csgo500Balance,
            grandTotal: data.grandTotal,
            steamInventoryWaxpeer: data.steamInventoryWaxpeer,
            steamInventoryBuff: data.steamInventoryBuff,
          };
          setBoxVisibility(mappedData);
        } else {
          console.error('Failed to fetch visibility preferences');
        }
      } catch (error) {
        console.error('Error fetching visibility preferences:', error);
      }
    };

    if (authenticated) {
      fetchVisibilityPrefs();
    }
  }, [authenticated]);
  // Fetch account modifiers and data
  const fetchModifications = async () => {
    try {
      const response = await fetch('/api/mainbalancemodifier', { credentials: 'include' });
      if (response.ok) {
        const data = await response.json();
        setAccountModifiers(data.accountsModifiers); // Set accountModifiers for use
        setNotification({ message: 'Balance modifiers loaded successfully.', type: 'success' });
      } else {
        console.error('Failed to fetch balance modifiers');
        setNotification({ message: 'Failed to fetch balance modifiers.', type: 'error' });
      }
    } catch (error) {
      console.error('Error fetching balance modifiers:', error);
      setNotification({ message: 'Error fetching balance modifiers.', type: 'error' });
    }
  };

  // Fetch accounts data
  const fetchAccountsData = async () => {
    try {
      const accountsResponse = await fetch('/api/account-details', { credentials: 'include' });
      if (accountsResponse.ok) {
        const accounts = await accountsResponse.json();
        setAccountsData(accounts);
        setNotification({ message: 'Accounts data loaded successfully.', type: 'success' });
        // Auto-select the first account on page load if accounts exist
        if (accounts.length > 0) {
          handleAccountSelect(accounts[0].nickname);
        }
      } else {
        console.error('Failed to fetch accounts data');
        setNotification({ message: 'Failed to fetch accounts data.', type: 'error' });
      }
    } catch (error) {
      console.error('Error fetching accounts data:', error);
      setNotification({ message: 'Error fetching accounts data.', type: 'error' });
    }
  };

  // Fetch balances for the selected account and apply modifiers
  const fetchBalances = async (nickname) => {
    try {
      const balancesResponse = await fetch(`/api/balances/${nickname}`, { credentials: 'include' });
      if (balancesResponse.ok) {
        const { balances } = await balancesResponse.json();

        // Ensure accountModifiers are available, if not set default to 100%
        const mods = accountModifiers || {
          waxpeerFeePercent: 100,
          marketFeePercent: 100,
          csgoEmpireFeePercent: 100,
          CSGORollFeePercent: 100,
          csgo500FeePercent: 100,
          steamInventoryBuff: 100,
          steamInventoryWaxpeer: 100,
          steamInventoryShadowpay: 100,
          steamInventoryMarket: 100,
        };

        // Apply the modifiers from accountModifiers
        const totalWaxpeer = (balances.WaxpeerBalance || 0) * (mods.waxpeerFeePercent / 100);
        const totalBuff163 = (balances.steamInventoryBuff || 0) * (mods.steamInventoryBuff / 100);
        const totalWaxpeerInv = (balances.steamInventoryWaxpeer || 0) * (mods.steamInventoryWaxpeer / 100);
        const totalMarketCSGO = (balances.MarketBalance || 0) * (mods.marketFeePercent / 100);
        const totalShadowPay = (balances.ShadowPayBalance || 0) * (mods.shadowpayBalancePercent / 100);
        const totalCsgoEmpire = (balances.CSGOEmpireBalance || 0) * (mods.csgoEmpireFeePercent / 100);
        const totalCSGORoll = (balances.CSGORollBalance || 0) * (mods.CSGORollFeePercent / 100);
        const totalCsgo500 = (balances.CSGO500Balance || 0) * (mods.csgo500FeePercent / 100);

        let GrandTotalforwaxpeer = totalWaxpeer + totalMarketCSGO + totalShadowPay + totalCsgoEmpire +totalCSGORoll+ totalCsgo500 + totalWaxpeerInv;
        let GrandTotalforbuff = totalWaxpeer + totalMarketCSGO + totalShadowPay + totalCsgoEmpire + totalCSGORoll+ totalCsgo500 + totalBuff163;
        console.log(totalWaxpeer, totalMarketCSGO, totalShadowPay, totalCsgoEmpire,totalCSGORoll, totalCsgo500);

        // Set the calculated values based on the response
        setCalculatedValues({
          waxpeer: GrandTotalforwaxpeer,
          buff163: GrandTotalforbuff,
          WaxpeerBalance: totalWaxpeer,
          MarketCSGOBalance: totalMarketCSGO,
          ShadowPayBalance: totalShadowPay,
          CsgoEmpireBalance: totalCsgoEmpire,
          CSGORollBalance: totalCSGORoll,
          Csgo500Balance: totalCsgo500,
          grandTotal: totalWaxpeer + totalMarketCSGO + totalShadowPay + totalCsgoEmpire +totalCSGORoll+ totalCsgo500,
          steamInventoryWaxpeer: totalWaxpeerInv,
          steamInventoryBuff: totalBuff163,
        });
        setNotification({ message: `Balances for ${nickname} loaded successfully.`, type: 'success' });

      } else {
        console.error('Failed to fetch balances');
        // Reset the balances to 0 in case of failure
        setCalculatedValues({
          waxpeer: 0,
          buff163: 0,
          WaxpeerBalance: 0,
          MarketCSGOBalance: 0,
          ShadowPayBalance: 0,
          CsgoEmpireBalance: 0,
          CSGORollBalance: 0,
          Csgo500Balance: 0,
          grandTotal: 0,
          steamInventoryWaxpeer: 0,
          steamInventoryBuff: 0,
        });
        setNotification({ message: 'Failed to fetch balances.', type: 'error' });
      }
    } catch (error) {
      setNotification({ message: 'Error fetching balances.', type: 'error' });
      console.error('Error fetching balances:', error);
    }
  };

  // Handle modifier changes for accounts (similar to the dashboard)
  const handleModifyValue = async (modifierType, key, percentage) => {
    // Update the account modifiers state and send the updated state immediately
    setAccountModifiers(prevModifiers => {
      const updatedModifiers = {
        ...prevModifiers,
        [key]: percentage
      };

      // Call the API with the updated modifiers
     

      return updatedModifiers;
    });
  };



  useEffect(() => {
    const checkAuth = async () => {
      try {
        if (hasFetched.current) return;
        hasFetched.current = true;

        const response = await fetch('/api/auth/isAuthenticated', { credentials: 'include' });
        if (response.ok) {
          setAuthenticated(true);
          await fetchModifications(); // Fetch the modifiers when authenticated
          await fetchAccountsData();
        } else {
          navigate('/');
          setNotification({ message: 'Authentication failed. Redirecting to home.', type: 'error' });
        }
      } catch (error) {
        console.error('Error checking authentication:', error);
        setNotification({ message: 'Error checking authentication.', type: 'error' });
        navigate('/');
      } finally {
        setLoading(false);
      }
    };
    checkAuth();
  }, [navigate]);

  // Handle account selection and fetch its balances
  const handleAccountSelect = (nickname) => {
    setSelectedAccountName(nickname);
    fetchBalances(nickname); // Fetch the balances for the selected account
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (settingsRef.current && !settingsRef.current.contains(event.target)) {
        setShowSettings(false);
      }
    };

    if (showSettings) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showSettings]);



  if (loading) {
    return (
      <div className="loading-screen">
        <div className="loading-spinner"></div>
      </div>
    );
  }

  return (
    <div className="accounts-page">
      <Navbar authenticated={authenticated} setAuthenticated={setAuthenticated} />
      <NotificationSlider message={notification.message} type={notification.type} />
      <div className="accounts-content">
        <div className="account-heading-container" style={{ position: 'relative' }}> {/* Ensure relative positioning for absolute children */}
          <h1 className="account-heading">Account: {selectedAccountName}</h1>
          {/* Include the settings icon directly with a unique class name */}
          <FaCog className="accounts-settings-icon" onClick={toggleSettingsPanel} />
        </div>

        <SettingsPanel 
          boxVisibility={boxVisibility} 
          handleVisibilityChange={handleVisibilityChange}
          saveVisibilityPreferences={() => {}}  
          settingsRef={settingsRef} 
          showSettings={showSettings}
          handleModifyValue={handleModifyValue} // Pass this function correctly
          modifications={accountModifiers} 
          modifierType="accounts"
        />

        <DashboardBoxes
          calculatedValues={calculatedValues}
          boxVisibility={boxVisibility}
          handleModifyValue={handleModifyValue}
          modifications={accountModifiers} 
        />

        <AccountsTable 
          accounts={accountsData} 
          onAccountSelect={handleAccountSelect} 
          onRefresh={fetchAccountsData} 
          setNotification={setNotification} // Pass setNotification here
        />
      </div>
      <Footer />
    </div>
  );
}

export default Accounts;
