// buymodules/BuySettingsMenu.js
import React, { useEffect, useRef } from 'react';
import './BuySettingsMenu.css';

function BuySettingsMenu({ 
    context, 
    selectedAccount, 
    priceRanges, 
    liquidityValues, 
    buyThresholds, // New prop for Buy Thresholds
    onPriceRangeChange, 
    onLiquidityChange,
    onBuyThresholdChange, // New handler for Buy Threshold
    onSaveChanges, // Function to handle save
    onCancelChanges, // Function to handle cancel
    closeMenu // Function to close the menu when clicked outside
}) {
    const menuRef = useRef(null);

    // useEffect to handle clicks outside of the settings menu
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                closeMenu(); // Close the menu when clicking outside
            }
        };

        // Attach the event listener
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            // Clean up the event listener on component unmount
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [closeMenu]);

    const markets = [
        { name: 'Market.CSGO', key: 'market' },
        { name: 'CSGOEmpire', key: 'csgoempire' },
        { name: 'Waxpeer', key: 'waxpeer' },
        { name: 'CSGO500', key: 'csgo500' },
        { name: 'ShadowPay', key: 'shadowpay' },
    ];

    return (
        <div className="buy-settings-menu" ref={menuRef}>
            <h3>{context === 'specific' ? 'Buy Settings' : 'Global Buy Settings'}</h3>
            <div className="buy-markets-container">
                {markets.map((market) => (
                    <div className="buy-market-item" key={market.key}>
                        <span className="buy-market-name">{market.name}</span>
                        
                        {/* Min and Max Price Range Inputs in a single row */}
                        <div className="buy-input-group-row">
                            <div className="buy-input-container">
                                <label htmlFor={`${market.key}-price-min`}>Min Price Range ($)</label>
                                <input
                                    type="number"
                                    id={`${market.key}-price-min`}
                                    min="0"
                                    max="10000"
                                    value={priceRanges[market.key]?.minPrice || ''}
                                    onChange={(e) => onPriceRangeChange(market.key, 'minPrice', e.target.value)}
                                    placeholder="Min"
                                    aria-label={`Minimum price range for ${market.name}`}
                                />
                            </div>

                            <div className="buy-input-container">
                                <label htmlFor={`${market.key}-price-max`}>Max Price Range ($)</label>
                                <input
                                    type="number"
                                    id={`${market.key}-price-max`}
                                    min="0"
                                    max="10000"
                                    value={priceRanges[market.key]?.maxPrice || ''}
                                    onChange={(e) => onPriceRangeChange(market.key, 'maxPrice', e.target.value)}
                                    placeholder="Max"
                                    aria-label={`Maximum price range for ${market.name}`}
                                />
                            </div>
                        </div>

                        {/* Liquidity Input */}
                        <div className="buy-input-group">
                            <label htmlFor={`${market.key}-liquidity`}>Liquidity (%)</label>
                            <input
                                type="number"
                                id={`${market.key}-liquidity`}
                                min="0"
                                max="100"
                                value={liquidityValues[market.key]?.liquidity || ''}
                                onChange={(e) => onLiquidityChange(market.key, e.target.value)}
                                placeholder="Liquidity (%)"
                                aria-label={`Liquidity for ${market.name}`}
                            />
                        </div>

                        {/* Buy Threshold Input */}
                        <div className="buy-input-group">
                            <label htmlFor={`${market.key}-buy-threshold`}>Buy Threshold (%)</label>
                            <input
                                type="number"
                                id={`${market.key}-buy-threshold`}
                                min="0"
                                max="200"
                                value={buyThresholds[market.key]?.threshold || ''}
                                onChange={(e) => onBuyThresholdChange(market.key, e.target.value)}
                                placeholder="Buy Threshold (%)"
                                aria-label={`Buy Threshold for ${market.name}`}
                            />
                        </div>
                    </div>
                ))}
            </div>
            {selectedAccount ? (
                <p className="selected-account">Account: {selectedAccount}</p>
            ) : (
                <p className="global-tag">Global Settings</p>
            )}
            
            {/* Save and Cancel Buttons */}
            <div className="buy-settings-buttons">
                <button className="buy-cancel-button" onClick={onCancelChanges}>Cancel</button>
                <button className="buy-save-button" onClick={onSaveChanges}>Save</button>
            </div>
        </div>
    );
}

export default BuySettingsMenu;
