// profitmodules/ProfitsTable.js
import React, { useEffect, useState } from 'react';
import './ProfitsTable.css';

function ProfitsTable({ selectedNickname }) { // Receive selectedNickname as prop
    const [profitsData, setProfitsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(false);

    useEffect(() => {
        // Reset to page 1 when selectedNickname changes
        setPage(1);
    }, [selectedNickname]);

    useEffect(() => {
        fetchProfits(page);
    }, [page, selectedNickname]);

    const fetchProfits = async (currentPage) => {
        try {
            setLoading(true);
            setError(null);

            // Build the query parameters
            let url = `/api/profits?page=${currentPage}&limit=20`;
            if (selectedNickname) {
                url += `&nickname=${encodeURIComponent(selectedNickname)}`;
            }

            const response = await fetch(url, {
                method: 'GET',
                credentials: 'include', // Include cookies
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to fetch profits data');
            }

            const data = await response.json();
            setProfitsData(data.profits);
            setHasMore(data.hasMore);
        } catch (err) {
            console.error(err);
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const handlePrevious = () => {
        if (page > 1) {
            setPage(prev => prev - 1);
        }
    };

    const handleNext = () => {
        if (hasMore) {
            setPage(prev => prev + 1);
        }
    };

    return (
        <div className="profits-table-wrapper">
            <h2 className="profits-heading">Profits History</h2>

            <div className="profits-table-container">
                <table className="profits-table">
                    <thead>
                        <tr>
                            <th>Acct</th>
                            <th>Item</th>
                            <th>Buy ($)</th>
                            <th>Sell ($)</th>
                            <th>Buy Mkt</th>
                            <th>Sell Mkt</th>
                            <th>Buy Date</th>
                            <th>Sell Date</th>
                            <th>Profit ($)</th>
                            <th>Profit (%)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {profitsData.length > 0 ? (
                            profitsData.map((record) => (
                                <tr key={record._id}>
                                    <td>{record.nickname || record.Accname}</td> {/* Show nickname if available */}
                                    <td>{record.ItemName}</td>
                                    <td>{record.BuyPrice.toFixed(2)}</td>
                                    <td>{record.SoldPrice.toFixed(2)}</td>
                                    <td>{record.boughtmarketplace || 'NotRecorded'}</td>
                                    <td>{record.soldmarketplace || 'NotRecorded'}</td>
                                    <td>{new Date(record.BoughtDate).toLocaleDateString()}</td>
                                    <td>{new Date(record.SoldDate).toLocaleDateString()}</td>
                                    <td>{record.Profit.toFixed(2)}</td>
                                    <td>{record.ProfitPercentage.toFixed(2)}%</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="10" className="no-data">No profit records available.</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

            {error && <div className="profits-table-error">Error: {error}</div>}
            {loading && <div className="profits-table-loading">Loading...</div>}

            <div className="pagination-controls">
                <button onClick={handlePrevious} disabled={page === 1 || loading}>
                    Previous
                </button>
                <button onClick={handleNext} disabled={!hasMore || loading}>
                    Next
                </button>
            </div>

            {!hasMore && !loading && profitsData.length > 0 && (
                <div className="no-more">No more records.</div>
            )}
        </div>
    );
}

export default ProfitsTable;
