// src/components/policies/RefundPolicy.jsx

import React from 'react';

const RefundPolicy = () => (
  <div>
    <h3>Refund Policy</h3>
    <p><strong>Last Updated:</strong> 01/12/2024</p>

    <h4>1. No Refunds</h4>
    <p>
      All funds deposited into your MasterPanel account are non-refundable under any circumstances. By using our Service, you acknowledge and agree that MasterPanel does not offer refunds for any reason.
    </p>

    <h4>2. Acknowledgment</h4>
    <p>
      By using our Service, you acknowledge and agree that you have read, understood, and agree to be bound by this Refund Policy.
    </p>

    <h4>3. Contact Us</h4>
    <p>
      If you have any questions about this Refund Policy, please contact us at:
    </p>
    <p>
      MasterPanel LLC<br />
      6844 Bardstown Rd #5052, Louisville, KY 40291
      40291
      Miami
      United States<br />
      support@masterspanel.com<br />
    </p>
  </div>
);

export default RefundPolicy;
