import React from 'react';
import { FaCog } from 'react-icons/fa';
import './DashboardBoxes.css';

function DashboardBoxes({ calculatedValues, boxVisibility, handleModifyValue, modifications }) {
  return (
    <>
      {/* Inventory Total Section */}
      <h2 className="section-heading">Inventory Total According To:</h2>
      <div className="dashboard-boxes">
        {boxVisibility.waxpeer && (
          <div className="dashboard-box">
            <h2 className="box-title">Waxpeer</h2>
            <p className="box-value">${calculatedValues.waxpeer?.toFixed(2)}</p>
          </div>
        )}
        {boxVisibility.buff163 && (
          <div className="dashboard-box">
            <h2 className="box-title">Buff163</h2>
            <p className="box-value">${calculatedValues.buff163?.toFixed(2)}</p>
          </div>
        )}
      </div>

      {/* Balances Section */}
      <h2 className="section-heading">Balances In:</h2>
      <div className="dashboard-boxes">
      {boxVisibility.MarketCSGOBalance && (
          <div className="dashboard-box">
            <h2 className="box-title">Market.CSGO</h2>
            <p className="box-value">${calculatedValues.MarketCSGOBalance?.toFixed(2)}</p>
            <FaCog className="settings-icon" onClick={() => handleModifyValue('marketFeePercent', prompt('Enter percentage:', modifications.marketFeePercent))} />
          </div>
        )}

      {boxVisibility.WaxpeerBalance && (
        <div className="dashboard-box">
          <h2 className="box-title">Waxpeer</h2>
          <p className="box-value">${calculatedValues.WaxpeerBalance?.toFixed(2)}</p>
          <FaCog className="settings-icon" onClick={() => handleModifyValue('waxpeerFeePercent', prompt('Enter percentage:', modifications.waxpeerFeePercent))} />
        </div>
      )}

        {boxVisibility.ShadowPayBalance && (
          <div className="dashboard-box">
            <h2 className="box-title">ShadowPay</h2>
            <p className="box-value">${calculatedValues.ShadowPayBalance?.toFixed(2)}</p>
            <FaCog className="settings-icon" onClick={() => handleModifyValue('shadowPayFeePercent', prompt('Enter percentage:', modifications.shadowPayFeePercent))} />
          </div>
        )}

        {boxVisibility.CsgoEmpireBalance && (
          <div className="dashboard-box">
            <h2 className="box-title">CSGOEmpire</h2>
            <p className="box-value">${calculatedValues.CsgoEmpireBalance?.toFixed(2)}</p>
            <FaCog className="settings-icon" onClick={() => handleModifyValue('csgoEmpireFeePercent', prompt('Enter percentage:', modifications.csgoEmpireFeePercent))} />
          </div>
        )}

        {boxVisibility.CSGORollBalance && (
          <div className="dashboard-box">
            <h2 className="box-title">CSGORoll</h2>
            <p className="box-value">${calculatedValues.CSGORollBalance?.toFixed(2)}</p>
            <FaCog className="settings-icon" onClick={() => handleModifyValue('CSGORollFeePercent', prompt('Enter percentage:', modifications.CSGORollFeePercent))} />
          </div>
        )}
        {boxVisibility.Csgo500Balance && (
          <div className="dashboard-box">
            <h2 className="box-title">CSGO500</h2>
            <p className="box-value">${calculatedValues.Csgo500Balance?.toFixed(2)}</p>
            <FaCog className="settings-icon" onClick={() => handleModifyValue('csgo500FeePercent', prompt('Enter percentage:', modifications.csgo500FeePercent))} />
          </div>
        )}

        {boxVisibility.grandTotal && (
          <div className="dashboard-box">
            <h2 className="box-title">Grand Total</h2>
            <p className="box-value">${calculatedValues.grandTotal?.toFixed(2)}</p>
          </div>
        )}
      </div>

      {/* Steam Inventories Section */}
      <h2 className="section-heading">In Steam Inventories According To:</h2>
      <div className="dashboard-boxes">
        {boxVisibility.steamInventoryWaxpeer && (
          <div className="dashboard-box">
            <h2 className="box-title">Waxpeer</h2>
            <p className="box-value">${calculatedValues.steamInventoryWaxpeer?.toFixed(2)}</p>
            <FaCog className="settings-icon" onClick={() => handleModifyValue('steamInventoryWaxpeer', prompt('Enter percentage:', modifications.steamInventoryWaxpeer))} />
          </div>
        )}
        {boxVisibility.steamInventoryBuff && (
          <div className="dashboard-box">
            <h2 className="box-title">Buff163</h2>
            <p className="box-value">${calculatedValues.steamInventoryBuff?.toFixed(2)}</p>
            <FaCog className="settings-icon" onClick={() => handleModifyValue('steamInventoryBuff', prompt('Enter percentage:', modifications.steamInventoryBuff))} />
          </div>
        )}
      </div>
    </>
  );
}

export default DashboardBoxes;
