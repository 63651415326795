import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './HomePage.css';
import Footer from './Homepagemodules/homepagefooter';
import SignupLoginSetup from './Homepagemodules/SignupLoginSetup';
import Offer from './Homepagemodules/Offer';
import Navbar from './Homepagemodules/Navbar';
import Content from './Homepagemodules/Content';
import ProblemSolution from './Homepagemodules/ProblemSolution';
import FAQ from './Homepagemodules/FAQ';
import Marketplaces from './Homepagemodules/Marketplaces'; // Import the new component
function HomePage() {
  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const navigate = useNavigate();
  const hasFetched = useRef(false);

  const handleButtonClick = () => {
    setShowForm(true);
  };

  const closeForm = () => {
    setShowForm(false);
  };

  useEffect(() => {
    const checkAuth = async () => {
      try {
        if (hasFetched.current) return;
        hasFetched.current = true;
        
        const response = await fetch('/api/auth/isAuthenticated', { credentials: 'include' });
        if (response.ok) {
          setAuthenticated(true);
        } else {
          navigate('/');
        }
      } catch (error) {
        console.error('Error checking authentication:', error);
        navigate('/');
      } finally {
        setLoading(false);
      }
    };

    checkAuth();
  }, [navigate]);

  // Reinitialize Trustpilot widget when the component mounts
  useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(document.getElementById('trustbox'), true);
    }
  }, []);

  return (
    <div className="homepage">
      <Navbar 
        onButtonClick={handleButtonClick} 
        authenticated={authenticated} 
        setAuthenticated={setAuthenticated} 
      />
      
      <Content 
        authenticated={authenticated} 
        setShowForm={setShowForm}
      />

      <ProblemSolution />

      <Marketplaces /> {/* Add the new component here */}
      
      <Offer />

      <FAQ />

      <Footer />
      
      {showForm && <SignupLoginSetup closeForm={closeForm} />}
    </div>
  );
}

export default HomePage;
