import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from './Homepagemodules/Navbar';
import './Dashboard.css';
import Footer from './Homepagemodules/homepagefooter';
import NotificationSlider from './components/NotificationSlider';
import './components/loadingscreen.css';
import DashboardHeader from './dashboardmodules/DashboardHeader';
import SettingsPanel from './dashboardmodules/SettingsPanel';
import DashboardBoxes from './dashboardmodules/DashboardBoxes';

function Dashboard() {
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const [calculatedValues, setCalculatedValues] = useState({});
  const [showSettings, setShowSettings] = useState(false);
  const settingsRef = useRef(null);
  const navigate = useNavigate();
  const hasFetched = useRef(false);
  const [notification, setNotification] = useState({ message: '', type: '' });
  const [boxVisibility, setBoxVisibility] = useState({
    waxpeer: true,
    buff163: true,
    MarketCSGOBalance: true,
    WaxpeerBalance: true,
    ShadowPayBalance: true,
    CsgoEmpireBalance: true,
    CSGORollBalance: true,
    Csgo500Balance: true,
    grandTotal: true,
    steamInventoryWaxpeer: true,
    steamInventoryBuff: true
  });

  const [modifications, setModifications] = useState({
    dashboardModifiers: {
      shadowpayBalancePercent: 100,
      waxpeerFeePercent: 100,
      marketFeePercent: 100,
      csgoEmpireFeePercent: 100,
      CSGORollFeePercent: 100,
      csgo500FeePercent: 100,
      steamInventoryBuff: 100,
      steamInventoryWaxpeer: 100,
      steamInventoryShadowpay: 100,
      steamInventoryMarket: 100,
      steamInventoryYoupin: 100
    },
    accountsModifiers: {
      shadowpayBalancePercent: 100,
      waxpeerFeePercent: 100,
      marketFeePercent: 100,
      csgoEmpireFeePercent: 100,
      CSGORollFeePercent: 100,
      csgo500FeePercent: 100,
      steamInventoryBuff: 100,
      steamInventoryWaxpeer: 100,
      steamInventoryShadowpay: 100,
      steamInventoryMarket: 100,
      steamInventoryYoupin: 100
    }
  });

  const toggleSettingsPanel = () => {
    setShowSettings(prev => !prev);
  };

  const handleVisibilityChange = (boxName) => {
    setBoxVisibility(prev => ({
      ...prev,
      [boxName]: !prev[boxName]
    }));
  };

  const saveVisibilityPreferences = async () => {
    try {
      const response = await fetch('/api/visibility-prefs', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({ visibilityPrefs: boxVisibility })
      });

      if (response.ok) {
        setNotification({ message: 'Visibility preferences saved successfully!', type: 'success' });
      } else {
        setNotification({ message: 'Failed to save visibility preferences', type: 'error' });
      }
    } catch (error) {
      console.error('Error saving visibility preferences:', error);
      setNotification({ message: 'Error saving visibility preferences', type: 'error' });
    }
  };

  const handleClickOutside = (event) => {
    if (settingsRef.current && !settingsRef.current.contains(event.target)) {
      setShowSettings(false);
    }
  };

  const handleModifyValue = async (modifierType, key, percentage) => {
    // Update modifications locally
    setModifications(prev => {
      const updatedModifications = {
        ...prev,
        [modifierType]: {
          ...prev[modifierType],
          [key]: percentage
        }
      };
  
      // Directly call calculateValues with the latest modifications and current balances

  
      return updatedModifications;
    });
  
    // Send the modified values to the API
    try {
      const modifiers = {
        [key]: percentage  // Wrap the key and percentage properly
      };
  
      const response = await fetch('/api/mainbalancemodifier', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({
          modifierType,  // 'dashboard' or 'accounts'
          modifiers      // Send the correct payload structure
        })
      });
  
      if (!response.ok) {
        setNotification({ message: 'Failed to update balance modifier', type: 'error' });
      } else {
        setNotification({ message: 'Modifier updated successfully!', type: 'success' });
  
        // After successfully updating the modifier, fetch the latest total balances
        await fetchUpdatedBalances();
      }
    } catch (error) {
      setNotification({ message: 'Error updating balance modifier', type: 'error' });
      console.error('Error updating balance modifier:', error);
    }
  };
  
  // Function to fetch the updated balances after modification
  const fetchUpdatedBalances = async () => {
    try {
      const balanceResponse = await fetch('/api/total-balances', { credentials: 'include' });
      if (balanceResponse.ok) {
        const totalBalances = await balanceResponse.json();
  
        // Pass the entire totalBalances response to calculateValues
        calculateValues(modifications.dashboardModifiers, totalBalances);  // This should handle the entire response
      } else {
        console.error('Failed to fetch updated total balances');
      }
    } catch (error) {
      console.error('Error fetching updated total balances:', error);
    }
  };
  

  const calculateValues = (mods, balances) => {
    //console.log("dasdgasddhuagkaysdjfbgsdjkhfgbsdkjhfsdbgfjmkhsb",balances)
    const MarketCSGOBalance = balances.totalBalances.MarketBalance * (mods.marketFeePercent / 100) || 0;
    const WaxpeerBalance = balances.totalBalances.WaxpeerBalance * (mods.waxpeerFeePercent / 100) || 0;
    const CsgoEmpireBalance = balances.totalBalances.CSGOEmpireBalance * (mods.csgoEmpireFeePercent / 100) || 0;
    const CSGORollBalance = balances.totalBalances.CSGORollBalance * (mods.CSGORollFeePercent / 100) || 0;
    const Csgo500Balance = balances.totalBalances.CSGO500Balance  * (mods.csgo500FeePercent / 100) || 0;
    const ShadowPayBalance = balances.totalBalances.ShadowPayBalance * (mods.shadowpayBalancePercent / 100) || 0;
    const grandTotal = (MarketCSGOBalance + WaxpeerBalance + CSGORollBalance + CsgoEmpireBalance + Csgo500Balance + ShadowPayBalance) || 0;

    const steamInventoryWaxpeer = balances.totalBalances.steamInventoryWaxpeer * (mods.steamInventoryWaxpeer / 100) || 0;
    const steamInventoryBuff = balances.totalBalances.steamInventoryBuff * (mods.steamInventoryBuff / 100) || 0;

    const GrandTotalforwaxpeer = (steamInventoryWaxpeer + MarketCSGOBalance + ShadowPayBalance + WaxpeerBalance + CsgoEmpireBalance + CSGORollBalance + Csgo500Balance) || 0;
    const GrandTotalforbuff = (steamInventoryBuff + MarketCSGOBalance + ShadowPayBalance + WaxpeerBalance + CsgoEmpireBalance + CSGORollBalance + Csgo500Balance) || 0;

    const values = { 
      waxpeer: GrandTotalforwaxpeer,
      buff163: GrandTotalforbuff,
      MarketCSGOBalance,
      WaxpeerBalance,
      CsgoEmpireBalance,
      CSGORollBalance,
      Csgo500Balance,
      ShadowPayBalance,
      grandTotal,
      steamInventoryWaxpeer,
      steamInventoryBuff
    };

    setCalculatedValues(values);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [balanceResponse, modifiersResponse] = await Promise.all([
          fetch('/api/total-balances', { credentials: 'include' }),
          fetch('/api/mainbalancemodifier', { credentials: 'include' })
        ]);

        if (balanceResponse.ok && modifiersResponse.ok) {
          const totalBalances = await balanceResponse.json();
          const { dashboardModifiers } = await modifiersResponse.json();

          setModifications({
            dashboardModifiers,
            accountsModifiers: dashboardModifiers // Assuming same values for now
          });

          calculateValues(dashboardModifiers, totalBalances);
        } else {
          console.error('Failed to fetch balances or modifiers');
        }
      } catch (error) {
        console.error('Error fetching balances or modifiers:', error);
      }
    };

    if (authenticated) {
      fetchData();
    }
  }, [authenticated]);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        if (hasFetched.current) return;
        hasFetched.current = true;

        const response = await fetch('/api/auth/isAuthenticated', { credentials: 'include' });
        if (response.ok) {
          setAuthenticated(true);
        } else {
          navigate('/');
        }
      } catch (error) {
        console.error('Error checking authentication:', error);
        navigate('/');
      } finally {
        setLoading(false);
      }
    };
    checkAuth();
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [navigate]);

  useEffect(() => {
    const fetchVisibilityPrefs = async () => {
      try {
        const response = await fetch('/api/visibility-prefs', { credentials: 'include' });
        if (response.ok) {
          const data = await response.json();
          const mappedData = {
            waxpeer: data.waxpeer,
            buff163: data.buff163,
            MarketCSGOBalance: data.MarketCSGOBalance,
            ShadowPayBalance : data.ShadowPayBalance,
            WaxpeerBalance: data.WaxpeerBalance,
            CsgoEmpireBalance: data.CsgoEmpireBalance,
            CSGORollBalance: data.CSGORollBalance,
            Csgo500Balance: data.Csgo500Balance,
            grandTotal: data.grandTotal,
            steamInventoryWaxpeer: data.steamInventoryWaxpeer,
            steamInventoryBuff: data.steamInventoryBuff,
          };
          setBoxVisibility(mappedData);
        } else {
          console.error('Failed to fetch visibility preferences');
        }
      } catch (error) {
        console.error('Error fetching visibility preferences:', error);
      }
    };

    if (authenticated) {
      fetchVisibilityPrefs();
    }
  }, [authenticated]);

  if (loading) {
    return (
      <div className="loading-screen">
        <div className="loading-spinner"></div>
      </div>
    );
  }

  if (!authenticated) {
    return null;
  }

  return (
    <div className="dashboard">
      <Navbar authenticated={authenticated} setAuthenticated={setAuthenticated} />
      <NotificationSlider message={notification.message} type={notification.type} />
      <div className="dashboard-content">
        <DashboardHeader toggleSettingsPanel={toggleSettingsPanel} />
        <SettingsPanel 
          boxVisibility={boxVisibility}
          handleVisibilityChange={handleVisibilityChange}
          saveVisibilityPreferences={saveVisibilityPreferences}
          settingsRef={settingsRef}
          showSettings={showSettings}
          handleModifyValue={(modifierType, modifiedValues) => handleModifyValue(modifierType, modifiedValues)}
          modifications={modifications.dashboardModifiers}
          modifierType="dashboard"
        />
        <DashboardBoxes
          calculatedValues={calculatedValues}
          boxVisibility={boxVisibility}
          handleModifyValue={(key, percentage) => handleModifyValue('dashboard', key, percentage)}
          modifications={modifications.dashboardModifiers}
        />
      </div>
      <Footer />
    </div>
  );
}

export default Dashboard;
