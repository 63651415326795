// src/components/NonSteamAccountForm.js
import React from 'react';
import './NonSteamAccountForm.css'; // Ensure this file contains the provided CSS styles

function NonSteamAccountForm({
  formData,
  setFormData,
  editableFields,
  toggleEditField,
  isEditMode,
}) {
  const {
    nickname,
    waxpeerApiKey,
    shadowpayApiKey,
    shadowpayWssToken,
    marketCsgoApiKey,
    csgoEmpireApiKey,
    CSGORollPassword,
    CSGORollEmailId,
    csgo500ApiKey,
    csgo500UserId,
    proxy,
  } = formData;

  // Function to determine if the proxy field should be required
  const isProxyRequired = () => {
    return (
      csgoEmpireApiKey.trim() !== '' ||
      CSGORollPassword.trim() !== '' ||
      CSGORollEmailId.trim() !== '' ||
      csgo500ApiKey.trim() !== '' ||
      csgo500UserId.trim() !== ''
    );
  };

  return (
    <div className="non-steam-account-form">
      {/* Row 1: Nickname */}
      <div className="form-row">
        <div className="form-group">
          <label htmlFor="nickname">Nickname (required)</label>
          <input
            type="text"
            id="nickname"
            name="nickname"
            value={nickname}
            onChange={(e) =>
              setFormData({ ...formData, nickname: e.target.value })
            }
            required
            readOnly={isEditMode} // Read-only in edit mode
          />
        </div>
      </div>

      {/* Row 2: Waxpeer API Key & Shadowpay API Key */}
      <div className="form-row">
        <div className="form-group">
          <label htmlFor="waxpeerApiKey">Waxpeer API Key</label>
          <input
            type="text"
            id="waxpeerApiKey"
            name="waxpeerApiKey"
            value={waxpeerApiKey}
            onChange={(e) =>
              setFormData({ ...formData, waxpeerApiKey: e.target.value })
            }
            disabled={!editableFields.waxpeerApiKey}
          />
          {isEditMode && (
            <button
              type="button"
              className="edit-btn"
              onClick={() => toggleEditField('waxpeerApiKey')}
              aria-label="Toggle edit for Waxpeer API Key"
            >
              {editableFields.waxpeerApiKey ? 'Lock' : 'Edit'}
            </button>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="shadowpayApiKey">Shadowpay API Key</label>
          <input
            type="text"
            id="shadowpayApiKey"
            name="shadowpayApiKey"
            value={shadowpayApiKey}
            onChange={(e) =>
              setFormData({ ...formData, shadowpayApiKey: e.target.value })
            }
            disabled={!editableFields.shadowpayApiKey}
          />
          {isEditMode && (
            <button
              type="button"
              className="edit-btn"
              onClick={() => toggleEditField('shadowpayApiKey')}
              aria-label="Toggle edit for Shadowpay API Key"
            >
              {editableFields.shadowpayApiKey ? 'Lock' : 'Edit'}
            </button>
          )}
        </div>
      </div>

      {/* Row 3: Shadowpay WSS Token & Market CSGO API Key */}
      <div className="form-row">
        <div className="form-group">
          <label htmlFor="shadowpayWssToken">Shadowpay WSS Token</label>
          <input
            type="text"
            id="shadowpayWssToken"
            name="shadowpayWssToken"
            value={shadowpayWssToken}
            onChange={(e) =>
              setFormData({ ...formData, shadowpayWssToken: e.target.value })
            }
            disabled={!editableFields.shadowpayWssToken}
          />
          {isEditMode && (
            <button
              type="button"
              className="edit-btn"
              onClick={() => toggleEditField('shadowpayWssToken')}
              aria-label="Toggle edit for Shadowpay WSS Token"
            >
              {editableFields.shadowpayWssToken ? 'Lock' : 'Edit'}
            </button>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="marketCsgoApiKey">Market CSGO API Key</label>
          <input
            type="text"
            id="marketCsgoApiKey"
            name="marketCsgoApiKey"
            value={marketCsgoApiKey}
            onChange={(e) =>
              setFormData({ ...formData, marketCsgoApiKey: e.target.value })
            }
            disabled={!editableFields.marketCsgoApiKey}
          />
          {isEditMode && (
            <button
              type="button"
              className="edit-btn"
              onClick={() => toggleEditField('marketCsgoApiKey')}
              aria-label="Toggle edit for Market CSGO API Key"
            >
              {editableFields.marketCsgoApiKey ? 'Lock' : 'Edit'}
            </button>
          )}
        </div>
      </div>

      {/* Row 4: CSGOEmpire API Key & CSGORoll Password */}
      <div className="form-row">
        <div className="form-group">
          <label htmlFor="csgoEmpireApiKey">CSGOEmpire API Key</label>
          <input
            type="text"
            id="csgoEmpireApiKey"
            name="csgoEmpireApiKey"
            value={csgoEmpireApiKey}
            onChange={(e) =>
              setFormData({ ...formData, csgoEmpireApiKey: e.target.value })
            }
            disabled={!editableFields.csgoEmpireApiKey}
          />
          {isEditMode && (
            <button
              type="button"
              className="edit-btn"
              onClick={() => toggleEditField('csgoEmpireApiKey')}
              aria-label="Toggle edit for CSGOEmpire API Key"
            >
              {editableFields.csgoEmpireApiKey ? 'Lock' : 'Edit'}
            </button>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="CSGORollPassword">CSGORoll Password</label>
          <input
            type="password"
            id="CSGORollPassword"
            name="CSGORollPassword"
            value={CSGORollPassword}
            onChange={(e) =>
              setFormData({ ...formData, CSGORollPassword: e.target.value })
            }
            disabled={!editableFields.CSGORollPassword}
          />
          {isEditMode && (
            <button
              type="button"
              className="edit-btn"
              onClick={() => toggleEditField('CSGORollPassword')}
              aria-label="Toggle edit for CSGORoll Password"
            >
              {editableFields.CSGORollPassword ? 'Lock' : 'Edit'}
            </button>
          )}
        </div>
      </div>

      {/* Row 5: CSGORoll Email Id, CSGO500 API Key & CSGO500 User ID */}
      <div className="form-row">
        <div className="form-group">
          <label htmlFor="CSGORollEmailId">CSGORoll Email Id</label>
          <input
            type="email"
            id="CSGORollEmailId"
            name="CSGORollEmailId"
            value={CSGORollEmailId}
            onChange={(e) =>
              setFormData({ ...formData, CSGORollEmailId: e.target.value })
            }
            disabled={!editableFields.CSGORollEmailId}
          />
          {isEditMode && (
            <button
              type="button"
              className="edit-btn"
              onClick={() => toggleEditField('CSGORollEmailId')}
              aria-label="Toggle edit for CSGORoll Email Id"
            >
              {editableFields.CSGGORollEmailId ? 'Lock' : 'Edit'}
            </button>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="csgo500ApiKey">CSGO500 API Key</label>
          <input
            type="text"
            id="csgo500ApiKey"
            name="csgo500ApiKey"
            value={csgo500ApiKey}
            onChange={(e) =>
              setFormData({ ...formData, csgo500ApiKey: e.target.value })
            }
            disabled={!editableFields.csgo500ApiKey}
          />
          {isEditMode && (
            <button
              type="button"
              className="edit-btn"
              onClick={() => toggleEditField('csgo500ApiKey')}
              aria-label="Toggle edit for CSGO500 API Key"
            >
              {editableFields.csgo500ApiKey ? 'Lock' : 'Edit'}
            </button>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="csgo500UserId">CSGO500 User ID</label>
          <input
            type="text"
            id="csgo500UserId"
            name="csgo500UserId"
            value={csgo500UserId}
            onChange={(e) =>
              setFormData({ ...formData, csgo500UserId: e.target.value })
            }
            disabled={!editableFields.csgo500UserId}
          />
          {isEditMode && (
            <button
              type="button"
              className="edit-btn"
              onClick={() => toggleEditField('csgo500UserId')}
              aria-label="Toggle edit for CSGO500 User ID"
            >
              {editableFields.csgo500UserId ? 'Lock' : 'Edit'}
            </button>
          )}
        </div>
      </div>

      {/* Row 6: Proxy */}
      <div className="form-row">
        <div className="form-group">
          <label htmlFor="proxy">
            Proxy {isProxyRequired() && '(required)'}
          </label>
          <input
            type="text"
            id="proxy"
            name="proxy"
            value={proxy}
            onChange={(e) =>
              setFormData({ ...formData, proxy: e.target.value })
            }
            placeholder="http://login:pass@IP:port"
            required={isProxyRequired()}
            disabled={!editableFields.proxy}
          />
          {isEditMode && (
            <button
              type="button"
              className="edit-btn"
              onClick={() => toggleEditField('proxy')}
              aria-label="Toggle edit for Proxy"
            >
              {editableFields.proxy ? 'Lock' : 'Edit'}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default NonSteamAccountForm;
